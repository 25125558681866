import React, { useState, useEffect, useRef } from 'react';
import { Grid,Avatar, Paper, Typography, Box, Button, CircularProgress, IconButton, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';import { useProfilePic } from '../context/ProfilePicContext'; // Adjust the import path as needed
import NavBar from '../HomePage/NavBar_home';
import './Profile.css';
import { BASE_URL } from '../config';
import ProfileField from './ProfileField';
import ProfileForm from './ProfileForm';
import ProfilePic from './ProfilePic';
import { getSessionData } from '../utils/session';





const ProfileComponent = () => {
  // const sessionData = getSessionData();
 
  const sessionData=JSON.parse(sessionStorage.getItem('userInfo'))
  const user_name = sessionData?.username;  
  const email=sessionData?.email;
  const defaultProfile = {
    country_born_in: null,
    current_country: null,
    religion: null,
    marital_status: null,
    first_name: null,
    last_name: null,
    city: null,
    gender: null,
    hobbies: null,
    DoB: null,
    user_name: user_name,
    user_age: null,
    user_bio: null,
  };
  console.log("user",user_name)
  const [errors, setErrors] = useState({});
  const { profilePic, setProfilePic } = useProfilePic();
  const inputFileRef = useRef(null);
  const [loadingCities, setLoadingCities] = useState(false);

  const toastRef = useRef(null);
  const [mode, setMode] = useState('view');
  const [profile, setProfile] = useState(defaultProfile);
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
 
  useEffect(() => {
    fetchProfileData();
  }, []);
  useEffect(() => {
    // Fetch cities when the country changes
    if (profile.country) {
      fetchCities(profile.country);
    }
  }, [profile.country]);
  
  const fetchProfileData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/profile_view`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_name: user_name,
          mode: 'view',
        }),
      });
      const data = await response.json();
      if (data.status === 'Success.') {
        console.log("view",data.response)
        setProfile(data.response);
      } else {
        console.error('Error fetching profile data:', data.process_status);
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    } finally {
      setIsLoading(false);
    }
  };

const payload_update={
  user_name: user_name,
  mode: 'update',
  user_profile_db_input: profile,
}
  const handleProfileSubmit = async (profile) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/profile_update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload_update),
      });

      const data = await response.json();
      if (data.status === 'Success.') {
        console.log('Profile updated successfully');
        console.log("update",payload_update);
        setMode('view');
        fetchProfileData(); // Refetch profile data after successful update
      } else {
        console.error('Error updating profile:', data.process_status);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  useEffect(() => {
  
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/country`);
      if (response.ok) {
        const data = await response.json();
        setCountries(data.response);
      } else {
        console.error('Failed to fetch countries');
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchCities = async (selectedCountry) => {
    try {
      const response = await fetch(`${BASE_URL}/api/city`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: selectedCountry,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        setCities(data.response);
      } else {
        console.error('Failed to fetch cities');
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };
  const handleCurrentCountryChange = async(event) => {
    const {name, value } = event.target;
    setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
    await fetchCities(value);
  };
  const handleCountryChange = (event) => {
    const { name, value } = event.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const profileFields1 = [
    { label: 'First Name', value: profile.first_name },
    { label: 'Family Name', value: profile.family_name },
    { label: 'Short Bio', value: profile.user_bio },
    { label: 'Birthdate', value: profile.DOB },

    { label: 'Gender', value: profile.gender },
    { label: 'Country born in', value: profile.country_born_in },
    { label: 'Current Country', value: profile.current_country },
  
    { label: 'City', value: profile.city },
    { label: 'Religion', value: profile.religion },

    { label: 'Marital Status', value: profile.marital_status },
    { label: 'Hobbies', value: profile.hobbies },
  ];
  const personalDetails = profileFields1.filter(field =>
    ['First Name', 'Family Name', 'Birthdate', 'Gender', 'Religion', 'Marital Status', 'Hobbies'].includes(field.label)
  );

  const addressDetails = profileFields1.filter(field =>
    ['Country born in', 'Current Country', 'City'].includes(field.label)
  );
  const [editMode, setEditMode] = useState({ profilePic: false, personal: false, address: false });

  const handleEditMode = (section) => {
    setEditMode(prev => ({ ...prev, [section]: !prev[section] }));
  };


  const handleSave = async (mode) => {
    try {
      const updatedProfile = { ...profile };
      // Update the `updatedProfile` object based on the mode (personal or address)

      await handleProfileSubmit(updatedProfile); // Assuming `updateProfile` is an API call
      setEditMode(prev => ({ ...prev, [mode]: false })); // Exit edit mode
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleCancel = (mode) => {
    setEditMode(prev => ({ ...prev, [mode]: false })); // Exit edit mode
  };

  useEffect(() => {
    fetchAndRenderImage(user_name);
  },);

  const fetchAndRenderImage = (user_name) => {
    fetch(`${BASE_URL}/fetch_image?user_name=${user_name}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // console.log(user_name)
          setProfilePic(data.response);
          // console.log(data.response)
          localStorage.setItem('image_data',profilePic)
        } else {
        //   setProfilePic(initialImage); // Assuming initialEncodedImage is defined
          console.error("No image found for this user");
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  return (
    <Box>
    <NavBar />
    <Grid container spacing={1} className="profileGridContainer">
    {/* <Grid item xs={11}>
      <Paper className="profileCard-pic" sx={{ padding: 2, position: 'relative' }}>
        <div className='card-pic'>
          <div>
            <Avatar
              alt="Profile Picture"
              src={profilePic}
              sx={{ width: 120, height: 120, marginBottom: 0 }}
            />
            {editMode.profilePic && (
              <ProfilePic 
                userName={profile.user_name} 
                profilePic={profilePic} 
                setProfilePic={setProfilePic} 
                editMode={editMode.profilePic} 
              />
            )}
          </div>
          <div>
            <Box position="absolute" right={20} top={20}>
              <IconButton onClick={() => handleEditMode('profilePic')}>
                <EditIcon />
              </IconButton>
            </Box>
            {editMode.profilePic ? (
              <Box>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '17px', marginBottom: 5 }}>
                  <span>Bio :</span>
                  <TextField
                    type="text"
                    name="user_bio"
                    value={profile.user_bio}
                    onChange={(e) => handleInputChange(e, 'user_bio')}
                    fullWidth
                    variant="outlined"
                   
                  />
                </Typography>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{
                    marginTop:'10px',
                    position: 'absolute',
                    bottom: 10,
                    right: 20,
                    width: 'calc(100% - 40px)' // Adjust width to avoid overflow
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave('profilePic')}
                    sx={{mr: 2, bgcolor: 'rgb(96, 8, 136)', '&:hover': { bgcolor: 'rgb(96, 8, 136)' } }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleCancel('profilePic')}
                    sx={{ color: 'rgb(96, 8, 136)', border: '1px solid rgb(96, 8, 136)', '&:hover': { border: '1px solid rgb(96, 8, 136)' } }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box marginTop={3}>
                <Typography 
                  sx={{ 
                    marginBottom: 2, 
                    fontFamily: 'Roboto', 
                    fontSize: { xs: '14px', sm: '15px' }, // Adjust font size for mobile and larger screens
                    wordWrap: 'break-word' // Ensure text wraps within the grid
                  }}
                >
                  <span>Registered Email :</span> {email}
                </Typography>
                <Typography 
                  sx={{ 
                    fontFamily: 'Roboto', 
                    fontSize: { xs: '14px', sm: '15px' }, // Adjust font size for mobile and larger screens
                    wordWrap: 'break-word' // Ensure text wraps within the grid
                  }}
                >
                  <span>Bio :</span> {profile.user_bio}
                </Typography>
              </Box>
            )}
          </div>
        </div>
      </Paper>
    </Grid> */}
    <Grid item xs={11}>
    <Paper className="profileCard-pic" sx={{ padding: 2, position: 'relative' }}>
        <div className='card-pic'>
          <div>
            <Avatar
              alt="Profile Picture"
              src={profilePic}
              sx={{ width: 120, height: 120, marginBottom: 0 }}
            />
            {editMode.profilePic && (
              <ProfilePic 
                userName={profile.user_name} 
                profilePic={profilePic} 
                setProfilePic={setProfilePic} 
                editMode={editMode.profilePic} 
              />
            )}
          </div>
          <Box position="absolute" right={20} top={20}>
            <IconButton onClick={() => handleEditMode('profilePic')}>
              <EditIcon />
            </IconButton>
          </Box>
          {editMode.profilePic && (
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{
                marginTop: '50px',
                position: 'absolute',
                bottom: 5,
            
                right: 20,
                width: 'calc(100% - 40px)' // Adjust width to avoid overflow
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSave('profilePic')}
                sx={{ mr: 2, bgcolor: 'rgb(96, 8, 136)', '&:hover': { bgcolor: 'rgb(96, 8, 136)' } }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleCancel('profilePic')}
                sx={{ color: 'rgb(96, 8, 136)', border: '1px solid rgb(96, 8, 136)', '&:hover': { border: '1px solid rgb(96, 8, 136)' } }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </div>
      </Paper>

      <Grid item xs={12}>
      <Paper sx={{ padding: 2, position: 'relative' }} className="profileCard">
          <Box>
            {/* Email Display */}
            <Box sx={{ backgroundColor: '#fafafa', padding: 1, borderRadius: 1, marginBottom: 2 }}>
              <Box display="flex" alignItems="center" sx={{ backgroundColor: 'white', padding: 1, borderRadius: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', flexShrink: 0, fontSize: '17px' }}>
                  Registered Email:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    marginLeft: 1,
                    marginRight:3,
                    fontSize: '17px',
                    overflowWrap: 'break-word', // Allows long words to break and wrap
                    wordBreak: 'break-word', // Breaks long words if needed
                    width: '100%', // Ensures full width of its container
                  }}
                >
                  {email}
                </Typography>
              </Box>
            </Box>

            {/* Bio Display and Edit Mode */}
            {editMode.bio ? (
              <Box>
                <Box sx={{ backgroundColor: '#fafafa', padding: 1, borderRadius: 1 }}>
                  <Box display="flex" alignItems="center" sx={{ backgroundColor: 'white', padding: 1, borderRadius: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', flexShrink: 0, fontSize: '17px' }}>
                      Bio:
                    </Typography>
                    <TextField
                      type="text"
                      name="user_bio"
                      value={profile.user_bio}
                      onChange={(e) => handleInputChange(e, 'user_bio')}
                      fullWidth
                      variant="outlined"
                      sx={{ marginLeft: 1, fontSize: '17px' }}
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '10px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave('bio')}
                    sx={{ mr: 2, bgcolor: 'rgb(96, 8, 136)', '&:hover': { bgcolor: 'rgb(96, 8, 136)' } }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleCancel('bio')}
                    sx={{ color: 'rgb(96, 8, 136)', border: '1px solid rgb(96, 8, 136)', '&:hover': { border: '1px solid rgb(96, 8, 136)' } }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                <Box sx={{ backgroundColor: '#fafafa', padding: 1, borderRadius: 1, marginBottom: 2 }}>
                  <Box display="flex" alignItems="center" sx={{ backgroundColor: 'white', padding: 1, borderRadius: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', flexShrink: 0, fontSize: '17px' }}>
                      Bio:
                    </Typography>
                    <Typography variant="body2" sx={{ marginLeft: 1, fontSize: '17px' }}>
                      {profile.user_bio}
                    </Typography>
                  </Box>
                </Box>
                <Box position="absolute" right={20} top={20}>
                  <IconButton onClick={() => handleEditMode('bio')}>
                    <EditIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Grid>

    </Grid>

      <Grid item xs={11}>
  <Paper className="profileCard">
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: 1 }}>
      <Typography variant="h5" sx={{ fontFamily: 'Roboto', fontWeight: 'bold', backgroundColor: 'transparent', padding: 1, borderRadius: 1 }}>
        Personal Information
      </Typography>
      <IconButton onClick={() => handleEditMode('personal')}>
        <EditIcon color='black'/>
      </IconButton>
    </Box>
    {editMode.personal ? (
      <ProfileForm
        profile={profile}
        handleInputChange={handleInputChange}
        handleCountryChange={handleCountryChange}
        handleCurrentCountryChange={handleCurrentCountryChange}
        errors={errors}
        countries={countries}
        cities={cities}
        loadingCities={loadingCities}
        mode="personal"
        onSave={() => handleSave('personal')}
        onCancel={() => handleCancel('personal')}
      />
    ) : (
<Box sx={{ marginTop: 2 }}>
  <Grid container spacing={2}>
    {personalDetails.map((field, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Box sx={{ backgroundColor: '#fafafa', padding: 1, borderRadius: 1 }}>
          <Box display="flex" alignItems="center" sx={{ backgroundColor: 'white', padding: 1, borderRadius: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', flexShrink: 0,fontSize:'17px' }}>
              {field.label}:
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: 1 ,fontSize:'17px'}}>
              {field.value}
            </Typography>
          </Box>
        </Box>
      </Grid>
    ))}
  </Grid>
</Box>


    )}
  </Paper>
</Grid>

<Grid item xs={11} mb={8}>
  <Paper className="profileCard">
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ fontFamily: 'Roboto', fontWeight: 'bold', backgroundColor: 'transparent', padding: 1, borderRadius: 1 }}>
        Address Information
      </Typography>
      <IconButton onClick={() => handleEditMode('address')}>
        <EditIcon />
      </IconButton>
    </Box>
    {editMode.address ? (
      <ProfileForm
        profile={profile}
        handleInputChange={handleInputChange}
        handleCountryChange={handleCountryChange}
        handleCurrentCountryChange={handleCurrentCountryChange}
        errors={errors}
        countries={countries}
        cities={cities}
        loadingCities={loadingCities}
        mode="address"
        onSave={() => handleSave('address')}
        onCancel={() => handleCancel('address')}
      />
    ) : (
      <Box sx={{ marginTop: 2 }}>
      <Grid container spacing={2}>
        {addressDetails.map((field, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box sx={{ backgroundColor: 'white', padding: 1, borderRadius: 1 }}>
              <Box display="flex" alignItems="center" sx={{ backgroundColor: 'white', padding: 1, borderRadius: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', flexShrink: 0 ,fontSize:'17px' }}>
                  {field.label}:
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 ,fontSize:'17px' }}>
                  {field.value}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
    
    )}
  </Paper>
</Grid>


      </Grid> 
    
      <div className="home-footer">
  <p>
    BAIA: Your AI-Powered Emotional Support Assistant{' '}
    <a
      href="https://www.baia.ai/terms-and-condition/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Terms
    </a>,{' '}
    <a
      href="https://www.baia.ai/disclaimer/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Disclaimer
    </a>{' '}
    and{' '}
    <a
      href="https://www.baia.ai/privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Privacy Policy
    </a> || Version: Beta-4.0
  </p>
</div>

    </Box>
  );
};

export default ProfileComponent;
