import React, { useState, useEffect, useRef, useContext,useCallback } from "react";
import Header from "./Header";
import MessageInput from './MessageInput';
import ChatMessage from "./ChatMessage"; 
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from '@mui/material'; 
import { useProfilePic } from '../context/ProfilePicContext'; 
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import "./FinalBody.css";
import AuthContext from '../AuthContext';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { BASE_URL } from "../config";
import 'react-h5-audio-player/lib/styles.css'; // Import default styles
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator
import AlertMessage from './AlertMessage'; // Import the new component
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getSessionData, saveSessionData } from '../utils/session';
import useAutoLogoff from '../useAutoLogoff';
import { useLanguage } from '../context/LanguageContext';
import NavBar from "./NavBar_home";
import CustomAudioPlayer from "./CustomAudioPlayer";
import { useTimer } from '../context/TimerContext';
import {fetchPreviousChat, fetchFreshChat,checkCentralSubscription } from "../api";
import img from '../Gif/baia-walking-animation.gif'
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';





const FinalBody = ({ className = "" }) => {
useAutoLogoff(10*60*1000);

const currentLanguage=sessionStorage.getItem('current_language')
// console.log(currentLanguage)
 
  const getEndpoints = (language) => {
    switch (language) {
      case 'French':
        return {
          fresh: 'api/fresh_french_chat',
          continuous: 'api/present_continue_french_chat',
        };
      case 'Spanish':
        return {
          fresh: 'api/fresh_spanish_chat',
          continuous: 'api/present_continue_spanish_chat',
        };
      case 'Hebrew':
        return {
          fresh: 'api/fresh_hebrew_chat',
          continuous: 'api/present_continue_hebrew_chat',
         
        };
      case 'English':
      default:
        return {
          fresh: 'api/fresh_chat',
          continuous: 'api/present_continue_chat',
        };
    }
  };
  const streaming_endpoint = currentLanguage === 'Hebrew' ? 'api/stream_chat_response_hebrew' : 'api/chat_response_stream';
  const { fresh, continuous } = getEndpoints(currentLanguage);
 
  const [freshChatMessages, setFreshChatMessages] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingText, setLoadingText] = useState('Starting Fresh chat BAIA is analyzing previous sessions. Please be patient; this may take 20 to 45 second');
  const { userInfo } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [freshChatActive, setFreshChatActive] = useState(false);
  const [previousChatActive, setPreviousChatActive] = useState(false);
  const [characterCode, setCharacterCode] = useState("BAIA-F-01_English-US");
  const chatBodyRef = useRef(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { profilePic, setProfilePic } = useProfilePic();
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true); 
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [showAssessmentAlert, setShowAssessmentAlert] = useState(false);
  const [pendingAssessments, setPendingAssessments] = useState([]);
  const [showProfileAlert, setShowProfileAlert] = useState(false);
  const [missingProfileData, setMissingProfileData] = useState([]);
  const chatType=localStorage.getItem('chatType');
  const voice_char = localStorage.getItem('voiceCharacter');
  // console.log(voice_char)
  const sessionData = getSessionData();
  const user_name = sessionData?.username;
  const [streaming, setStreaming] = useState(false);
  const[load, setLoading]=useState(false)
  const flag=localStorage.getItem('flag')
  // const voiceChar=localStorage.getItem('voiceCharacter')
  const email_id=sessionData?.email

  const voiceConsent = JSON.parse(sessionStorage.getItem('consent'));
   
  const details=JSON.parse(sessionStorage.getItem('central_subscription'))
  const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
  const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

  // console.log('central subscription details:- ', details, user_name);
  const [showConsentAlert, setShowConsentAlert] = useState(false);
  const handleCloseConsentAlert = () => setShowConsentAlert(false);

  // const isSubscribed = details.subscription_status === true ||  initial_details.Chat_analysis===true.


  useEffect(() => {
    const consent = sessionStorage.getItem('consent');
    if ((details.product_name=='PRO' || details.product_name=='ADVACED' || initialcasualChatStatus) && consent === 'false') {
      setShowConsentAlert(true);
    }
  }, []);
  useEffect(() => {


    const profileAlertTimeout = setTimeout(() => {
      checkProfileAlert();
    }, 10000); 
    return () => clearTimeout(profileAlertTimeout);
  }, []); 

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const checkAssessmentAlert = () => {
    fetch(`${BASE_URL}/api/assessment_alert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_name: user_name 
      })
    })
      .then(response => response.json())
      .then(data => {
        // console.log('Assessment Alert Response:', data);
        const assessmentsAlert = data.response.assessments_alert;
        const assessmentsNotGiven = data.response.assessments_not_given_by_user;
        if (assessmentsAlert && assessmentsNotGiven.length > 0) {
          const transformedAssessments = assessmentsNotGiven.map(assessment => {
            return assessment
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
          });
          setPendingAssessments(transformedAssessments);
          
          setShowAssessmentAlert(true);
        } else {
          setShowAssessmentAlert(false);
        }
      })
      .catch(error => {
        console.error('Error fetching assessment alert:', error);
      });
  };

  const checkProfileAlert = () => {
    fetch(`${BASE_URL}/api/profile_alert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_name: user_name
      })
    })
      .then(response => response.json())
      .then(data => {
        // console.log('Profile Alert Response:', data);
        const profileAlert = data.response.profile_alert;
        const missingData = data.response.missing_data;

        if (profileAlert && missingData.length > 0) {
          const transformedMissingData = missingData.map(field => {
            return field
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
          });
          setMissingProfileData(transformedMissingData);
          setShowProfileAlert(true);
        } else {
          setShowProfileAlert(false);
        }
      })
      .catch(error => {
        console.error('Error fetching profile alert:', error);
      });
  };

  const handleCloseAssessmentAlert = () => { setShowAssessmentAlert(false);};
  const handleCloseProfileAlert = () => {setShowProfileAlert(false);};

 

  useEffect(() => {
    const fetchSubscriptionDetails = () => {
      const availableSubscription = JSON.parse(localStorage.getItem('available_subscription'));
      if (availableSubscription) {
        const productName = JSON.parse(localStorage.getItem('product_name'));
        const subscriptionStatus = JSON.parse(localStorage.getItem('subscription_status'));
        setSubscriptionDetails({ productName, subscriptionStatus });
        setIsSubscribed(true);
        checkAssessmentAlert();
        const interval = setInterval(() => {
          checkAssessmentAlert();
        }, 10 * 60 * 1000); 
        return () => clearInterval(interval); 
      }
    };
    fetchSubscriptionDetails();
  }, []); 

  const getAudioPlayerIndices = (messages) => {
    let indices = [];
    let count = 0;
    
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].display_message && messages[i].role=='assistant') {
        indices.push(i);
        count++;
      }
      if (count === 2) {
        // console.log(indices)
        break;}
    }
  
    return indices;
  };
  
  useEffect(() => {
    

    const loadAndUpdateMessages = async (chatType) => {
      const storageKey = chatType === 'fresh' ? 'freshChat' : 'previousChat';
      // const storedMessages = JSON.parse(sessionStorage.getItem(storageKey)) || [];
      let storedMessages = sessionStorage.getItem(storageKey);

      // Check if storedMessages is valid JSON
      if (storedMessages) {
        try {
          storedMessages = JSON.parse(storedMessages);
        } catch (error) {
          console.error('Error parsing stored messages:', error);
          storedMessages = [];
        }
      } else {
        storedMessages = [];
      }
    
      const indicesToUpdate = getAudioPlayerIndices(storedMessages);
      // console.log({chatType,storedMessages})
      // console.log("index",indicesToUpdate)
      const messagesToUpdate = indicesToUpdate.map(index => storedMessages[index]);
      // console.log(messagesToUpdate)
      const updatedMessages = await updateMessagesWithAudio(messagesToUpdate);
      // console.log(updatedMessages)
      indicesToUpdate.forEach((index, i) => {
        storedMessages[index] = updatedMessages[i];
      });
      // console.log(storedMessages)
      setMessages(storedMessages)
      sessionStorage.setItem(storageKey, JSON.stringify(storedMessages));
    }
    const updateMessagesWithAudio = async (messages) => {
      const updatedMessages = await Promise.all(messages.map(async (message) => {
        if (message.role === 'assistant') {
          try {
            const ttsPayload = {
              text: message.content,
              character_voice_code: voice_char
            };
  
            const response = await fetch(`${BASE_URL}/api/text-to-speech`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(ttsPayload),
            });
  
            if (!response.ok) {
              throw new Error('Text to speech API request failed');
            }
  
            const blob = await response.blob();
            const newAudioUrl = URL.createObjectURL(blob);
            return { ...message, audioUrl: newAudioUrl };
          } catch (error) {
            console.error('Error converting text to speech:', error);
            return { ...message, audioUrl: null };
          }
        } else {
          return message;
        }
      }));
  
      return updatedMessages;
    };
    loadAndUpdateMessages(chatType)
  }, []); 
  
  
  // const [isRenderingState, setIsRenderingState] = useState(false); // For re-render triggering
  const isRenderingRef = useRef(false); // For persistent reference
  useEffect(() => {
    const isRendering = sessionStorage.getItem('isRendering');
    if (isRendering === 'true') {
      isRenderingRef.current = true;
      // setIsRenderingState(true);
    } else {
      isRenderingRef.current = false;
      // setIsRenderingState(false);
    }
  }, []);

  const handleSendMessage =  useCallback (async (message) => {
    // setIsRenderingState(true);
    isRenderingRef.current = true;

    const newUserMessage = { content: message, role: 'user' };
    const updatedMessages = [...messages, newUserMessage];
    setMessages(updatedMessages);
    setLoading(true);

    // console.log("f22")
    // console.log(isRenderingRef.current)
    // Add a placeholder for the assistant message with loading set to true
    setMessages(prevMessages => [
      ...prevMessages,
      { role: 'assistant', content: '', loading: true }
    ]);
   

      try {
      const response = await fetch(`${BASE_URL}/${streaming_endpoint }`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/event-stream"
        },
        body: JSON.stringify({ messages: updatedMessages })
      });
  
      if (!response.body) {
        console.error('Readable stream not supported');
        setLoading(false);
        // setIsRendering(false);
        return;
      }
      // console.log(isRenderingRef.current);
            await handleStreamingResponse(response, updatedMessages, message);
  
    } catch (error) {
      console.error('Error sending message:', error);
      // setIsRendering(false);
      setLoading(false);
    }
    finally{
      sessionStorage.setItem('isRendering', 'false');
      isRenderingRef.current = false;
      // setIsRenderingState(false);
      // console.log(isRenderingRef.current)

    }
  },[messages]);
  

  
  // Function to handle streaming responses
  const handleStreamingResponse = async (response, updatedMessages,message) => {
   

    
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
  
    let buffer = '';
    let fullAssistantMessage = '';
    let assistantMessageIndex = updatedMessages.length;

    while (true) {
      
      const { done, value } = await reader.read();
      if (done) break;
  
      buffer += decoder.decode(value, { stream: true });
  
      let boundary = buffer.indexOf('\n');
      while (boundary !== -1) {
        const chunk = buffer.slice(0, boundary).trim();
        buffer = buffer.slice(boundary + 1);
        boundary = buffer.indexOf('\n');
  
        if (chunk.startsWith('data:')) {
          setLoading(false);
          await new Promise(resolve => setTimeout(resolve, 90));
          
          const dataStr = chunk.replace('data: data:', '').trim();
          // console.log(dataStr)
          if (dataStr) {
            try {
              // console.log(dataStr)
              const data = JSON.parse(dataStr);
              const text = data.choices[0]?.delta?.content || '';
              if (!isRenderingRef.current) {
                reader.cancel();
                break;
              }
              if (text) {
                fullAssistantMessage += text;
                setMessages(prevMessages => {
                  const newMessages = [...prevMessages];
                  newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
                  return newMessages;
                });
              }
            } catch (error) {
              console.error('Error parsing chunk data:', error);
            }

          }
        }
      }
    }
 
    if (buffer.trim()) {
      try {
        const data = JSON.parse(buffer.trim().replace('data: data:', ''));
        const text = data.choices[0]?.delta?.content || '';
        if (text) {
          fullAssistantMessage += text;
          setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
            return newMessages;
          });
        }
      } catch (error) {
        console.error('Error parsing final buffer data:', error);
      }
    }
  
    const finalMessages = [...updatedMessages];
    finalMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
    setMessages(finalMessages);
    // setIsRendering(false);

    sessionStorage.setItem(
      'chatMessages',
      JSON.stringify(finalMessages.filter((msg) => msg.role === 'user' || msg.role === 'assistant'))
    );
  
    await updateConversation(fullAssistantMessage, message);
    if (voiceConsent) {
      await convertTextToSpeech(fullAssistantMessage, assistantMessageIndex, finalMessages);
    }
  };
  const getSessionId = (chatType) => {
    const sessionId = sessionStorage.getItem('sessionId');
    return `${sessionId}-${chatType}`;
  };
  const sessionId = getSessionId(chatType);
  // Function to update conversation
  const updateConversation = async (fullAssistantMessage, message) => {
    // const payload = {
   
    const payload = {
      users: [
        { user_name: user_name, content: message, role: 'user', chat_type: chatType, session_id: sessionId },
        { user_name: user_name, content: fullAssistantMessage, role: 'assistant', chat_type: chatType, session_id: sessionId }
      ]
    };

    // console.log(payload)
    try {
      const response = await fetch(`${BASE_URL}/api/conversation_update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      if (data.status === 'Success.') {

        console.log('User conversation updated successfully.');
      }
    } catch (error) {
      console.error('Error updating conversation:', error);
    }
    finally{
      sessionStorage.setItem('isRendering',false)

    }
  };
  
  // Function to convert text to speech
  const convertTextToSpeech = async (fullAssistantMessage, assistantMessageIndex, finalMessages) => {
    const ttsPayload = {
      text: fullAssistantMessage,
      character_voice_code: voice_char,
    };
  
    try {
      const response = await fetch(`${BASE_URL}/api/text-to-speech`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ttsPayload),
      });
  
      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
  
      setMessages(prevMessages => {
        const newMessages = [...prevMessages];
        newMessages[assistantMessageIndex] = {
          ...newMessages[assistantMessageIndex],
          audioUrl: audioUrl,
          display_message: true
        };
        newMessages[assistantMessageIndex - 1] = {
          ...newMessages[assistantMessageIndex - 1],
          display_message: true
        };
        if (chatType === 'fresh') {
          sessionStorage.setItem('freshChat', JSON.stringify(newMessages));
        } else {
          sessionStorage.setItem('previousChat', JSON.stringify(newMessages));
        }
        // setIsRendering(false);
        return newMessages;
      });
    } catch (error) {
      console.error('Error converting text to speech:', error);
      // setIsRendering(false);
    }
  };
  

  const handleFreshChatClick = async () => {
    localStorage.setItem('chatType', 'fresh');
    setLoadingText('Initiating a new chat session. BAIA is currently analyzing your previous interactions. Please be patient as this process may take 20 to 45 seconds.');
  
    let freshChatMessages = sessionStorage.getItem('freshChat');
  
    // Check if freshChatMessages is null, undefined, or an empty string
    if (!freshChatMessages || freshChatMessages.length === 0) {
      console.log('Fetching fresh chat...');
      const userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
      const user_name = userinfo.username;
      const freshEndpoint = sessionStorage.getItem('freshEndpoint'); // Make sure this is set correctly
  
      try {
        setIsLoading(true); // Set loading to true before fetching fresh chat
        freshChatMessages = await fetchFreshChat(user_name, freshEndpoint);
        sessionStorage.setItem('freshChat', JSON.stringify(freshChatMessages));
        // console.log('Fetched fresh chat messages:', freshChatMessages);
      } catch (error) {
        console.error('Error fetching fresh chat messages:', error);
        setLoadingText('Failed to load fresh chat messages. Please try again.');
        setIsLoading(false); // Set loading to false in case of error
        return;
      
      } finally {
        setIsLoading(false); // Ensure loading is set to false after fetching
      }
    }
  
    setMessages(JSON.parse(sessionStorage.getItem('freshChat')));
    setFreshChatActive(true);
    setPreviousChatActive(false); // Reset previous chat active state
  };
  
  

  const handlePreviousChatClick = () => {
    localStorage.setItem('chatType','previous')

    // console.log("ChatType",chatType);
    setLoadingText('BAIA is analyzing previous sessions, please be patient. It may take up to 20-45 seconds...');
   let days = localStorage.getItem('days');
    if (!days) {
      days = 1;
      localStorage.setItem('days', days);
    }
    setMessages(JSON.parse(sessionStorage.getItem('previousChat')))
  
    setPreviousChatActive(true);
    setFreshChatActive(false); 
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Use MUI's breakpoint for mobile view
  // console.log("ChatType",chatType);
  const audioPlayerStyles = {
    marginLeft: isMobile ? '50px' : '65px',
    marginTop: '8px',
    width: isMobile ? '80%' : '50%',
  };


  const audioPlayerIndices = getAudioPlayerIndices(messages);
 
  const [editedMessage, setEditedMessage] = useState(null); // Track the edited message
    const [totalTimer, setTotalTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const totalSeconds=JSON.parse(sessionStorage.getItem('availed-time'))
  const hours = Math.floor(totalSeconds / 3600) || 0;
  const minutes = Math.floor((totalSeconds % 3600) / 60) || 0;
  const seconds = totalSeconds % 60 || 0;
 
  const { localTimer, t } = useTimer();
   useEffect(() => {setTotalTime({hours,minutes,seconds}) }, []);


   const handleEditMessage = (index, newMessage) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[index].content = newMessage;

      // Remove all messages following the edited message
      const truncatedMessages = updatedMessages.slice(0, index );

      // Set the edited message to trigger the effect
      setEditedMessage(newMessage);

      // Return truncated messages to update the state
      return truncatedMessages;
    });
  };

  useEffect(() => {
    if (editedMessage !== null) {
      // Ensure state update is processed before sending the message
      handleSendMessage(editedMessage);
      setEditedMessage(null); // Clear the edited message state
    }
  }, [editedMessage]);


  return (
       
    <div className="main-container">
      <div>
      <NavBar/>
      </div>
   
    <main className={`final-body ${className}`}>
   
      <section className="content-background" />
      <section className="body">
        
        <Header PreviousChat={handlePreviousChatClick} FreshChat={handleFreshChatClick} />
        <div className="main-body">
          {isLoading ? (
           <div className='loading-screen-home'>
            {/* <CircularProgress className="progress-indicator" size={40} /> */}
              <div className="loading-circle-home"></div>
         <div className="load-text">{loadingText}</div>
          </div>
        
          ) : (

<div className="chat-body-background" ref={chatBodyRef}>
  

  {messages.map((msg, index, array) => (
    <React.Fragment key={index}>
      {(msg.display_message === undefined || msg.display_message) ? (
        <Box sx={{ marginBottom: 2 }}>
          <ChatMessage
            key={index}
            message={msg.content}
            isUser={msg.role === 'user'}
            loading={load && msg.role === 'assistant' && msg.content === ''}
            onEdit={(updatedMessage) => handleEditMessage(index, updatedMessage)}          />
          {msg.role === 'assistant' && (audioPlayerIndices.includes(index)) && voiceConsent &&(
            <CustomAudioPlayer src={msg.audioUrl} />
          )}
        </Box>
      ) : null}
    </React.Fragment>
  ))}
</div>


)}
      </div>
      <AlertMessage
        showAssessmentAlert={showAssessmentAlert}
        pendingAssessments={pendingAssessments}
        handleCloseAssessmentAlert={handleCloseAssessmentAlert}
        showProfileAlert={showProfileAlert}
        missingProfileData={missingProfileData}
        handleCloseProfileAlert={handleCloseProfileAlert}
        showConsentAlert={showConsentAlert}
        handleCloseConsentAlert={handleCloseConsentAlert}
      />
    
       
        <MessageInput
        onSend={handleSendMessage}
        isRenderingRef={isRenderingRef}
      />

      </section>
    </main>
    </div>

  );
};

FinalBody.propTypes = {
  className: PropTypes.string,
};

export default FinalBody;

