import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputBase, IconButton, Popover, Box } from '@mui/material';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon, AttachFile as AttachFileIcon, Close as CloseIcon } from '@mui/icons-material';
import Picker from '@emoji-mart/react';
import { useTheme, useMediaQuery } from '@mui/material';
import SubscriptionModal from '../utils/SubscriptionModal';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import * as nsfwjs from 'nsfwjs'; // Import NSFW.js


const MessageInput = ({ onSend, isRenderingRef }) => {
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const abortControllerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const recognitionRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const inputFileRef = useRef(null);
  const [model, setModel] = useState(null); // State to hold the NSFW.js model

  const sessionData = JSON.parse(sessionStorage.getItem('userInfo'));
  const user_name = sessionData.username;
  const postfixedUserName = `${user_name}_chatimage`;

  // Show Mic Icon logic
  const details_product_name = JSON.parse(sessionStorage.getItem('central_subscription')).product_name;
  const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
  const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

  const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
    sessionStorage.getItem('consent') === 'true';

    useEffect(() => {
      const loadModel = async () => {
        const loadedModel = await nsfwjs.load();
        setModel(loadedModel);
      };
      loadModel();
    }, []);

  const checkSubscriptionStatus = () => {
    const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
    const intial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')).enable_casual_chat_session_status;

    if (intial_sub) {
      return { canProceed: true, subscriptionData };
    }

    if (subscriptionData.subscription_status) {
      if (subscriptionData.daily_time_limit_quota) {
        return { canProceed: false, text: "Your daily time limit quota is over. Please subscribe to the Pro version." };
      }

      return { canProceed: true, subscriptionData };
    }

    return { canProceed: false, text: "No central subscription." };
  };

  // Function to handle microphone click
  const handleMicClick = () => {
    if (isListening) {
      recognitionRef.current.stop();
      setIsListening(false);
    } else {
      if (!('webkitSpeechRecognition' in window)) {
        alert('Speech recognition not supported in your browser. Please use Google Chrome.');
        return;
      }

      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.lang = 'en-US';
      recognitionRef.current.interimResults = false;
      recognitionRef.current.maxAlternatives = 1;

      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setMessage(prev => prev + (prev ? ' ' : '') + transcript);
        setCursorPosition(message.length + transcript.length);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
      };

      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  const detectNudity = async (file) => {
    if (!model) {
      console.error("Model not loaded");
      return false; // Model is not loaded
    }
  
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = async () => {
          const predictions = await model.classify(img);
  
          // Log the first prediction
          const firstPrediction = predictions[0];
          console.log("First detected class:", firstPrediction.className, "with probability:", firstPrediction.probability);
  
          // Check if the first prediction is Porn or Hentai
          const nudityDetected = (firstPrediction.className === 'Porn' || firstPrediction.className === 'Hentai') && firstPrediction.probability > 0.5;
          
          resolve(nudityDetected);
        };
  
        img.onerror = () => reject('Error loading image');
      };
  
      reader.readAsDataURL(file);
    });
  };
  
  
  // Function to handle image upload and preview
  const handlePhotoChatUpload = async () => {
    const file = inputFileRef.current.files[0];
    if (file && file.size > 10 * 1024 * 1024) {
      alert("File size limit exceeded");
      return;
    }

    if (file) {
      const nudityDetected = await detectNudity(file);
      if (nudityDetected) {
        alert("The image contains content that is against our policy and will not be uploaded.");
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
      }
    }
  };
  

  // Function to send message and image
  const handleSend = async () => {
    const { canProceed, text } = checkSubscriptionStatus();

    if (message.trim() || selectedFile) {
      if (!canProceed) {
        handleOpenModal(text);
        return;
      }

      let imageUrl = null;

      if (selectedFile) {
        try {
          imageUrl = await uploadImage(); // Upload the image first
        } catch (error) {
          alert(error);
          return;
        }
      }

      // Send the message with image URL (if available)
      onSend({
        text: message,
        imageUrl: imageUrl || null, // Use the uploaded image URL if available
      }, abortControllerRef.current);

      setMessage('');  // Clear the message input
      setSelectedFile(null); // Clear the selected image after sending
    }
  };

  // Function to upload image to the server
  const uploadImage = async () => {
    if (!selectedFile) return null;

    setIsUploading(true);

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (e) => {
        const base64data = e.target.result;
        const formData = {
          base64data: base64data,
          filename: selectedFile.name,
          user_name: postfixedUserName,
        };

        try {
          const response = await fetch('https://t3yb59mz86.l4b.ai/upload', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });

          const data = await response.json();

          if (data.success) {

            console.log("data image:- ", data)
            resolve(data.response); // Use 'data.response' to get the S3 URL
          } else {
            reject(data.error || 'Failed to upload image');
          }
        } catch (error) {
          console.error('Error:', error);
          reject('An error occurred while uploading the file.');
        } finally {
          setIsUploading(false);
        }
      };

      reader.readAsDataURL(selectedFile);
    });
  };

  // Function to remove selected image
  const handleRemoveImage = () => {
    setSelectedFile(null);
  };

  // Function to handle Enter key press
  const handleKeyPress = (event) => {
    const { canProceed, text } = checkSubscriptionStatus();

    if (event.key === 'Enter') {
      if (!canProceed) {
        handleOpenModal(text);
        return;
      }
      if (!event.shiftKey && !isMobile) {
        event.preventDefault();
        handleSend();
      }
    }
  };

  // Function to handle opening the subscription modal
  const handleOpenModal = (message) => {
    alert(message); // This can be replaced with a modal dialog
    setIsModalOpen(true);
  };


  return (
    <div className="text-field" style={{ paddingTop: 0, marginTop: 0 }}> {/* Ensured no top padding or margin */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Picker set="apple" onEmojiSelect={(emoji) => {
            const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
            setMessage(newMessage);
            setCursorPosition(cursorPosition + emoji.native.length);
          }} />
        </Box>
      </Popover>
  
      <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative', marginTop: '0' }}> {/* Removed margin-top */}
        <div style={{ flex: 1, marginRight: '100px' }}>
          {/* Display the selected image */}
          {selectedFile && (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected"
                style={{ maxHeight: '60px', marginRight: '10px', borderRadius: '8px' }} 
              />
              {/* Reduced image height */}
              <IconButton onClick={handleRemoveImage}>
                <CloseIcon sx={{ color: 'red' }} />
              </IconButton>
            </div>
          )}
  
          <InputBase
            placeholder="Type a message..."
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyPress={handleKeyPress}
            multiline
            sx={{
              width: '100%',
              maxWidth: '1000px',
              padding: '6px',  // Reduced padding
              backgroundColor: 'white',
              borderRadius: '15px',  // Slightly more compact border-radius
              minHeight: '40px',  // Reduced minimum height
              fontSize: '15px',   // Slightly smaller font size
              lineHeight: 1.5,    // Reduced line height for compactness
              overflowY: 'auto',
              fontFamily: 'Roboto',
            }}
          />
        </div>
  
        <div className="icons-container" style={{ position: 'absolute', right: '15px', bottom: '5px', display: 'flex', alignItems: 'center', zIndex: 1 }}>
          {/* Emoji Picker */}
          <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
            <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
          </IconButton>
  
          {/* File Upload Button */}
          <input
            type="file"
            ref={inputFileRef}
            style={{ display: 'none' }}
            onChange={handlePhotoChatUpload}
          />
          <IconButton onClick={() => inputFileRef.current.click()}>
            <AttachFileIcon sx={{ color: 'rgb(65, 6, 83)' }} />
          </IconButton>
  
          {/* Microphone Icon */}
          {shouldShowMicIcon && (
            <IconButton onClick={handleMicClick}>
              {isListening ? (
                <MicIcon sx={{ color: 'rgb(65, 6, 83)' }} />
              ) : (
                <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
              )}
            </IconButton>
          )}
  
          {/* Send Button */}
          <IconButton onClick={handleSend} disabled={isUploading}>
            {isUploading ? (
              <StopCircleIcon sx={{ color: 'gray' }} />
            ) : (
              <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
            )}
          </IconButton>
        </div>
      </div>
  
      <SubscriptionModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );

  
  
  // return (
  //   <div className="text-field" style={{ paddingTop: 0, marginTop: 0 }}>
  //     <Popover
  //       open={Boolean(anchorEl)}
  //       anchorEl={anchorEl}
  //       onClose={() => setAnchorEl(null)}
  //       anchorOrigin={{
  //         vertical: 'top',
  //         horizontal: 'left',
  //       }}
  //       transformOrigin={{
  //         vertical: 'bottom',
  //         horizontal: 'left',
  //       }}
  //     >
  //       <Box p={2}>
  //         <Picker set="apple" onEmojiSelect={(emoji) => {
  //           const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
  //           setMessage(newMessage);
  //           setCursorPosition(cursorPosition + emoji.native.length);
  //         }} />
  //       </Box>
  //     </Popover>
  
  //     <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
  //       <div style={{ flex: 1, marginRight: '100px' }}>
  //         {/* Display the selected image */}
  //         {selectedFile && (
  //           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
  //             <img
  //               src={URL.createObjectURL(selectedFile)}
  //               alt="Selected"
  //               style={{ maxHeight: '60px', marginRight: '10px', borderRadius: '8px' }} 
  //             />
  //             {/* Reduced image height */}

              
  //             <IconButton onClick={handleRemoveImage}>
  //               <CloseIcon sx={{ color: 'red' }} />
  //             </IconButton>
  //           </div>
  //         )}
  
  //         <InputBase
  //           placeholder="Type a message..."
  //           value={message}
  //           onChange={(event) => setMessage(event.target.value)}
  //           onKeyPress={handleKeyPress}
  //           multiline
  //           sx={{
  //             width: '100%',
  //             maxWidth: '1000px',
  //             padding: '6px',  // Reduced padding
  //             backgroundColor: 'white',
  //             borderRadius: '15px',  // Slightly more compact border-radius
  //             minHeight: '40px',  // Reduced minimum height
  //             fontSize: '15px',   // Slightly smaller font size
  //             lineHeight: 3,    // Reduced line height for compactness
  //             overflowY: 'auto',
  //             fontFamily: 'Roboto',
  //           }}
  //         />
  //       </div>
  
  //       <div className="icons-container" style={{ position: 'absolute', right: '15px', bottom: '5px', display: 'flex', alignItems: 'center', zIndex: 1 }}>
  //         {/* Emoji Picker */}
  //         <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
  //           <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //         </IconButton>
  
  //         {/* File Upload Button */}
  //         <input
  //           type="file"
  //           ref={inputFileRef}
  //           style={{ display: 'none' }}
  //           onChange={handlePhotoChatUpload}
  //         />
  //         <IconButton onClick={() => inputFileRef.current.click()}>
  //           <AttachFileIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //         </IconButton>
  
  //         {/* Microphone Icon */}
  //         {shouldShowMicIcon && (
  //           <IconButton onClick={handleMicClick}>
  //             {isListening ? (
  //               <MicIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //             ) : (
  //               <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //             )}
  //           </IconButton>
  //         )}
  
  //         {/* Send Button */}
  //         <IconButton onClick={handleSend} disabled={isUploading}>
  //           {isUploading ? (
  //             <StopCircleIcon sx={{ color: 'gray' }} />
  //           ) : (
  //             <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //           )}
  //         </IconButton>
  //       </div>
  //     </div>
  
  //     <SubscriptionModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
  //   </div>
  // );
  
};

MessageInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  isRenderingRef: PropTypes.object.isRequired,
};

export default MessageInput;

