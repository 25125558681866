

// // import React, { useRef, useState } from 'react';
// // import PlayCircleIcon from '@mui/icons-material/PlayCircle';
// // import PauseCircleIcon from '@mui/icons-material/PauseCircle';
// // import VolumeUpIcon from '@mui/icons-material/VolumeUp';
// // import VolumeDownIcon from '@mui/icons-material/VolumeDown';
// // import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
// // import { useMediaQuery } from '@mui/material';
// // import { useTheme } from '@mui/material/styles';

// // const CustomAudioPlayer = ({ src }) => {
// //   const audioRef = useRef(null);
// //   const [isPlaying, setIsPlaying] = useState(false);
// //   const [volume, setVolume] = useState(1); // Volume ranges from 0.0 (mute) to 1.0 (full volume)
// //   const [isValidSource, setIsValidSource] = useState(true); // Check if the source is valid
// //   const theme = useTheme();
// //   const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the view is mobile

// //   const color = 'rgb(70, 3, 90)'; // Define the color to be used

// //   const handlePlayPause = () => {
// //     if (audioRef.current) {
// //       if (isValidSource) {
// //         if (isPlaying) {
// //           audioRef.current.pause();
// //         } else {
// //           audioRef.current.play().catch(() => setIsValidSource(false));
// //         }
// //         setIsPlaying(!isPlaying);
// //       }
// //     }
// //   };

// //   const handleVolumeChange = (event) => {
// //     const newVolume = parseFloat(event.target.value);
// //     setVolume(newVolume);
// //     if (audioRef.current) {
// //       audioRef.current.volume = newVolume;
// //     }
// //   };

// //   return (
// //     <div 
// //     style={{ display: 'flex',
// //      alignItems: 'center',
// //     //   flexDirection: isMobile ? 'column' : 'row',
// //        backgroundColor: 'white', borderRadius: '5px', 
// //        padding: isMobile ? '5px' : '5px', 
// //        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
// //        width:isMobile?'150px':'210px' ,
// //        borderRadius:"5px",
// //        marginLeft:"65px"
// //        }}>
// //       <button
// //         onClick={handlePlayPause}
// //         disabled={!isValidSource}
// //         style={{
// //           backgroundColor: 'white',
// //           border: `2px solid ${color}`,
// //           borderRadius: '5px',
// //           cursor: isValidSource ? 'pointer' : 'not-allowed',
// //           outline: 'none',
// //           padding: '0px',
// //           margin: isMobile ? '0' : '0 5px',
// //           display: 'flex',
// //           alignItems: 'center',
// //           justifyContent: 'center'
// //         }}
// //       >
// //         {isValidSource ? (
// //           isPlaying ? (
// //             <PauseCircleIcon style={{ fontSize: isMobile ? 30 : 40, color: color }} />
// //           ) : (
// //             <PlayCircleIcon style={{ fontSize: isMobile ? 30 : 40, color: color }} />
// //           )
// //         ) : (
// //           <PlayCircleIcon style={{ fontSize: isMobile ? 30 : 40, color: 'lightgray' }} />
// //         )}
// //       </button>
// //       <audio ref={audioRef} src={src} onError={() => setIsValidSource(false)} />
// //       <div style={{ display: 'flex', alignItems: 'center', marginLeft: isMobile ? '0' : '10px' }}>
// //         {volume === 0 ? (
// //           <VolumeMuteIcon style={{ fontSize: isMobile ? 20 : 25, color: color }} />
// //         ) : volume < 0.5 ? (
// //           <VolumeDownIcon style={{ fontSize: isMobile ? 20 : 25, color: color }} />
// //         ) : (
// //           <VolumeUpIcon style={{ fontSize: isMobile ? 20 : 25, color: color }} />
// //         )}
// //         <input
// //           type="range"
// //           min="0"
// //           max="1"
// //           step="0.01"
// //           value={volume}
// //           onChange={handleVolumeChange}
// //           style={{
// //             marginLeft: '5px',
// //             width: isMobile ? '80px' : '100px',
// //             accentColor: color, // Set the slider fill color
// //           }}
// //         />
// //       </div>
// //     </div>
// //   );
// // };

// // export default CustomAudioPlayer;


// import React, { useRef, useState, useEffect } from 'react';
// import PlayCircleIcon from '@mui/icons-material/PlayCircle';
// import PauseCircleIcon from '@mui/icons-material/PauseCircle';
// import VolumeUpIcon from '@mui/icons-material/VolumeUp';
// import VolumeDownIcon from '@mui/icons-material/VolumeDown';
// import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
// import { useMediaQuery } from '@mui/material';
// import { useTheme } from '@mui/material/styles';

// const CustomAudioPlayer = ({ src }) => {
//   const audioRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [volume, setVolume] = useState(1); // Volume ranges from 0.0 (mute) to 1.0 (full volume)
//   const [isValidSource, setIsValidSource] = useState(true); // Check if the source is valid
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the view is mobile

//   const color = 'rgb(70, 3, 90)'; // Define the color to be used

//   useEffect(() => {
//     if (audioRef.current && isValidSource) {
//       audioRef.current.play().catch(() => setIsValidSource(false));
//       setIsPlaying(true);
//     }
//   }, [src, isValidSource]);

//   const handlePlayPause = () => {
//     if (audioRef.current) {
//       if (isValidSource) {
//         if (isPlaying) {
//           audioRef.current.pause();
//         } else {
//           audioRef.current.play().catch(() => setIsValidSource(false));
//         }
//         setIsPlaying(!isPlaying);
//       }
//     }
//   };

//   const handleVolumeChange = (event) => {
//     const newVolume = parseFloat(event.target.value);
//     setVolume(newVolume);
//     if (audioRef.current) {
//       audioRef.current.volume = newVolume;
//     }
//   };

//   return (
//     <div 
//       style={{
//         display: 'flex',
//         alignItems: 'center',
//         backgroundColor: 'white', 
//         borderRadius: '5px', 
//         padding: isMobile ? '5px' : '5px', 
//         boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
//         width: isMobile ? '150px' : '210px',
//         marginLeft: '65px'
//       }}
//     >
//       <button
//         onClick={handlePlayPause}
//         disabled={!isValidSource}
//         style={{
//           backgroundColor: 'white',
//           border: `2px solid ${color}`,
//           borderRadius: '5px',
//           cursor: isValidSource ? 'pointer' : 'not-allowed',
//           outline: 'none',
//           padding: '0px',
//           margin: isMobile ? '0' : '0 5px',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center'
//         }}
//       >
//         {isValidSource ? (
//           isPlaying ? (
//             <PauseCircleIcon style={{ fontSize: isMobile ? 30 : 40, color: color }} />
//           ) : (
//             <PlayCircleIcon style={{ fontSize: isMobile ? 30 : 40, color: color }} />
//           )
//         ) : (
//           <PlayCircleIcon style={{ fontSize: isMobile ? 30 : 40, color: 'lightgray' }} />
//         )}
//       </button>
//       <audio ref={audioRef} src={src} onError={() => setIsValidSource(false)} />
//       <div style={{ display: 'flex', alignItems: 'center', marginLeft: isMobile ? '0' : '10px' }}>
//         {volume === 0 ? (
//           <VolumeMuteIcon style={{ fontSize: isMobile ? 20 : 25, color: color }} />
//         ) : volume < 0.5 ? (
//           <VolumeDownIcon style={{ fontSize: isMobile ? 20 : 25, color: color }} />
//         ) : (
//           <VolumeUpIcon style={{ fontSize: isMobile ? 20 : 25, color: color }} />
//         )}
//         <input
//           type="range"
//           min="0"
//           max="1"
//           step="0.01"
//           value={volume}
//           onChange={handleVolumeChange}
//           style={{
//             marginLeft: '5px',
//             width: isMobile ? '80px' : '100px',
//             accentColor: color, // Set the slider fill color
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// export default CustomAudioPlayer;


// Comended code for trial


//  New code added by gourav 

import React, { useRef, useState, useEffect } from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CustomAudioPlayer = ({ src }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1); // Volume ranges from 0.0 (mute) to 1.0 (full volume)
  const [isValidSource, setIsValidSource] = useState(true); // Check if the source is valid
  const [audioEnded, setAudioEnded] = useState(false); // Track if audio has ended
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the view is mobile
  const recognitionRef = useRef(null);

  const color = 'rgb(70, 3, 90)'; // Define the color to be used

  useEffect(() => {
    if (audioRef.current && isValidSource) {
      audioRef.current.play().catch(() => setIsValidSource(false));
      setIsPlaying(true);
    }
  }, [src, isValidSource]);

  useEffect(() => {
    if (!audioEnded) {
      // Set session variable to true when audio ends
      sessionStorage.setItem('audioPlayed', 'true');
    }
    else{
      sessionStorage.setItem('audioPlayed', 'false');
      // sessionStorage.setItem('audioEnded', 'false');

    }
  }, [audioEnded]);

  // useEffect(() => {
  //   if (audioEnded) {
  //     // Set session variable to true when audio ends
  //     sessionStorage.setItem('audioPlayed', 'true');
  //   }
  //   
  // }, [audioEnded]);


  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isValidSource) {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play().catch(() => setIsValidSource(false));
        }
        setIsPlaying(!isPlaying);
      }
    }
  };

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
    setAudioEnded(true); // Mark audio as ended
    recognitionRef.current?.stop();

  };

  return (
    <div 
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white', 
        borderRadius: '5px', 
        padding: isMobile ? '5px' : '5px', 
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
        width: isMobile ? '150px' : '210px',
        marginLeft: '65px'
      }}
    >
      <button
        onClick={handlePlayPause}
        disabled={!isValidSource}
        style={{
          backgroundColor: 'white',
          border: `2px solid ${color}`,
          borderRadius: '5px',
          cursor: isValidSource ? 'pointer' : 'not-allowed',
          outline: 'none',
          padding: '0px',
          margin: isMobile ? '0' : '0 5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {isValidSource ? (
          isPlaying ? (
            <PauseCircleIcon style={{ fontSize: isMobile ? 30 : 40, color: color }} />
          ) : (
            <PlayCircleIcon style={{ fontSize: isMobile ? 30 : 40, color: color }} />
          )
        ) : (
          <PlayCircleIcon style={{ fontSize: isMobile ? 30 : 40, color: 'lightgray' }} />
        )}
      </button>
      <audio 
        ref={audioRef} 
        src={src} 
        onError={() => setIsValidSource(false)} 
        onEnded={handleAudioEnded} // Add this event handler
      />
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: isMobile ? '0' : '10px' }}>
        {volume === 0 ? (
          <VolumeMuteIcon style={{ fontSize: isMobile ? 20 : 25, color: color }} />
        ) : volume < 0.5 ? (
          <VolumeDownIcon style={{ fontSize: isMobile ? 20 : 25, color: color }} />
        ) : (
          <VolumeUpIcon style={{ fontSize: isMobile ? 20 : 25, color: color }} />
        )}
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          style={{
            marginLeft: '5px',
            width: isMobile ? '80px' : '100px',
            accentColor: color, // Set the slider fill color
          }}
        />
      </div>
    </div>
  );
};

export default CustomAudioPlayer;



