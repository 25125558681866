



import React, { useState, useEffect } from 'react';
import { Box, FormControl, Select, MenuItem,TextField, Button, Typography, InputLabel, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { useLanguage } from '../context/LanguageContext';
import { useProfileImageContext } from '../context/ProfileImageContext';
import { BASE_URL } from '../config';
import { getSessionData } from '../utils/session';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import NavBar from '../HomePage/NavBar_home';
import { fetchPreviousChat, fetchFreshChat } from '../api';
import './setting.css';
import { fetchVoiceConcent, updateVoiceConcent } from '../api'; // Assuming updateVoiceConcent exists
import LockIcon from '@mui/icons-material/Lock';
import TranslateIcon from '@mui/icons-material/Translate';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import FormatSizeIcon from '@mui/icons-material/FormatSize';

const SettingsPage = () => {
  const { availableLanguages, updateLanguage } = useLanguage();
  const [gender, setGender] = useState('Female');
  const [voiceCharacter, setVoiceCharacter] = useState('');
  const [characterCodes, setCharacterCodes] = useState({ Female: [], Male: [] });
  const { profileImage, updateProfileImage } = useProfileImageContext();
  const sessionData = getSessionData();
  const user_name = sessionData?.username;
  const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
  const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

  const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const voiceConsent = JSON.parse(sessionStorage.getItem('consent'));
  const [hasVoiceConsent, setHasVoiceConsent] = useState(voiceConsent);
  const [voiceConsentChecked, setVoiceConsentChecked] = useState(false);
  const [showVoiceCharacterSettings, setShowVoiceCharacterSettings] = useState(false);
// console.log(voiceConsent)
const details=JSON.parse(sessionStorage.getItem('central_subscription'))

  const handleVoiceConsentSubmit = async () => {
    try {
const response=null;
      if((details.product_name!='PRO' && details.product_name!='ADVACED' || initialcasualChatStatus))
      {
        const response = await updateVoiceConcent(user_name,false);
        if (response) {
          setHasVoiceConsent(true); // Update the consent status
          setShowVoiceCharacterSettings(true); // Show voice character settings
         
          
            sessionStorage.setItem('consent', JSON.stringify(false)); 
          
        
        }
      }
      else{
        const response = await updateVoiceConcent(user_name, true);
     
        if (response) {
          setHasVoiceConsent(true); // Update the consent status
          setShowVoiceCharacterSettings(true); // Show voice character settings
         
          sessionStorage.setItem('consent', JSON.stringify(true)); // Update session storage
         
        
        }
      }
     
     
   
    } catch (error) {
      console.error('Error updating voice consent:', error);
    }
  };

  const handleCheckboxChange = (event) => {
    setVoiceConsentChecked(event.target.checked);
  };

  const filterCharacterCodes = (codes, language) => {
    const filteredFemaleCodes = codes.Female.filter((code) => code.includes(language));
    const filteredMaleCodes = codes.Male.filter((code) => code.includes(language));
    return { Female: filteredFemaleCodes, Male: filteredMaleCodes };
  };


  useEffect(() => {
    // const fetchCharacterCodes = async () => {
    //   try {
    //     const response = await fetch(`${BASE_URL}/api/list_character_code`);
    //     if (!response.ok) throw new Error('Failed to fetch character codes');
    //     const data = await response.json();
    //     const { Female = [], Male = [] } = data.response || {};
    //     // setCharacterCodes({ Female, Male });
    //     setCharacterCodes(filterCharacterCodes({ Female, Male }, selectedLanguage));
    //     console.log(characterCodes)
    //   } catch (error) {
    //     console.error('Error fetching character codes:', error);
    //   }
    // };
    const current_lanuage=sessionStorage.getItem('current_language');
    const fetchCharacterCodes = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/list_character_code_ma`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ selected_language:  current_lanuage}),
        });
        if (!response.ok) throw new Error('Failed to fetch character codes');
        const data = await response.json();
        const { Female = [], Male = [] } = data.response || {};
        setCharacterCodes({ Female, Male });
        console.log(characterCodes);
      } catch (error) {
        console.error('Error fetching character codes:', error);
      }
    };
    

    const fetchInitialCharacterData = async () => {
      const payload = { user_name: user_name };
      try {
        const response = await fetch(`${BASE_URL}/api/get_character_code_ma`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });

        if (!response.ok) throw new Error('Failed to fetch character data');
        const data = await response.json();
        if (data.status === 'Success.') {
          const { assistant_character, assistant_gender } = data.response;
          console.log("Character Code",data.response)
          setGender(assistant_gender || 'Female');
          setVoiceCharacter(assistant_character || '');
          updateProfileImage(assistant_gender || 'Female');
          localStorage.setItem('gender', assistant_gender || 'Female');
          localStorage.setItem('voiceCharacter', assistant_character || '');
        } else {
          setGender('Female');
          setVoiceCharacter('BAIA-F-01_English-US');
          localStorage.setItem('gender', 'Female');
          localStorage.setItem('voiceCharacter', 'BAIA-F-01_English-US');
        }
      } catch (error) {
        console.error('Error fetching character data:', error);
        setGender('Female');
        setVoiceCharacter('BAIA-F-01_English-US');
        localStorage.setItem('gender', 'Female');
        localStorage.setItem('voiceCharacter', 'BAIA-F-01_English-US');
      }
    };

    fetchCharacterCodes();
    fetchInitialCharacterData();
  }, [user_name, updateProfileImage]);

  const handleGenderChange = (event) => {
    const selectedGender = event.target.value;
    setGender(selectedGender);
    setVoiceCharacter('');
    updateProfileImage(selectedGender);
  };

  const handleVoiceCharacterChange = (event) => {
    const selectedCharacter = event.target.value;
    setVoiceCharacter(selectedCharacter);
  };

  const handleSubmit = async () => {
    updateProfileImage(gender);
    const payload = { user_name: user_name, gender: gender, character_code: voiceCharacter };
    // console.log("Paylod for update character",payload)
    localStorage.setItem('voiceCharacter', voiceCharacter);
    localStorage.setItem('gender', gender);
    try {
      const response = await fetch(`${BASE_URL}/api/update_character_code`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data.status === 'Success.') {
        console.log("Updated Successfully")
        window.location.reload();
      } else {
        console.error('Failed to update character code:', data.process_status);
      }
    } catch (error) {
      console.error('Error updating character code:', error);
    }
  };

  const getEndpoints = (language) => {
    switch (language) {
      case 'French':
        return { fresh: 'api/fresh_french_chat', continuous: 'api/present_continue_french_chat' };
      case 'Spanish':
        return { fresh: 'api/fresh_spanish_chat', continuous: 'api/present_continue_spanish_chat' };
      case 'Hebrew':
        return { fresh: 'api/fresh_hebrew_chat', continuous: 'api/present_continue_hebrew_chat' };
      case 'English':
      default:
        return { fresh: 'api/fresh_chat', continuous: 'api/present_continue_chat' };
    }
  };

  const updateEndpointsInSessionStorage = (language) => {
    const endpoints = getEndpoints(language);
    sessionStorage.setItem('freshEndpoint', endpoints.fresh);
    sessionStorage.setItem('continuousEndpoint', endpoints.continuous);
    return endpoints;
  };

  const updateChatMessagesInSessionStorage = async (language) => {
    const userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
    const userName = userinfo.username;
    const { fresh, continuous } = updateEndpointsInSessionStorage(language);
    try {
      sessionStorage.setItem('Chat_flag',true);
      const freshChatMessages = await fetchFreshChat(userName, fresh);
      sessionStorage.setItem('freshChat', JSON.stringify(freshChatMessages));
    } catch (error) {
      console.error('Error fetching fresh chat messages:', error);
    }

    try {
      
      
      const previousChatMessages = await fetchPreviousChat(userName, 1, continuous);
      sessionStorage.setItem('Chat_flag',false);
      sessionStorage.setItem('previousChat', JSON.stringify(previousChatMessages));
    } catch (error) {
      console.error('Error fetching previous chat messages:', error);
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const updateLanguage_new = async (language,userName) => {

    const currentDateTime = new Date().toISOString();
    const payload = {
      user_name: userName,
      language: language,
      timestamp: currentDateTime,
    };
// console.log("paylod for langauage",payload)
    try {
      const response = await fetch(`${BASE_URL}/api/update_language`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.response === 'Done') {
        sessionStorage.setItem('current_language', selectedLanguage);    
        } else {
        console.error('Failed to update language.');
      }
    } catch (error) {
      console.error('Error updating language:', error);
    }
  };
  const handleUpdateLanguage = async () => {
    setIsLoading(true);
    await updateLanguage_new(selectedLanguage,user_name);
    await updateLanguage(selectedLanguage,user_name);
    sessionStorage.setItem('current_language', selectedLanguage);

    await updateChatMessagesInSessionStorage(selectedLanguage);
    setIsLoading(false);
    setIsEditMode(false);
    window.location.reload(); 
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    setSelectedLanguage(sessionStorage.getItem('current_language') || 'English');
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
  };
  const [productName, setProductName] = useState('');

  useEffect(() => {
    const userInfo = JSON.parse(sessionStorage.getItem('central_subscription'));
    if (userInfo && userInfo.product_name) {

      setProductName(userInfo.product_name);
    }
    // console.log(userInfo.product_name)

  }, []);
  const [selectedSection, setSelectedSection] = useState('language');

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };
  const isProOrAdvancedUser = (productName === 'PRO' || productName === 'ADVACED' || initialcasualChatStatus);
  console.log("Temp",isProOrAdvancedUser)

  const handleFontSizeChange = (e) => {
    setNewFontSize(e.target.value);
  };
  const [fontSize, setFontSize] = useState(sessionStorage.getItem('font_size') || '16');
  const [newFontSize, setNewFontSize] = useState(fontSize);

  const payload_font={ 
    user_name: user_name,
     font_size: parseInt(newFontSize),
     timestamp: new Date().toISOString(),
    }
    // console.log(payload_font)
    const handleUpdateFontSize = async () => {
      const payload_font = { 
        user_name: user_name,
        font_size: parseInt(newFontSize, 10),
        timestamp: new Date().toISOString(),
      };
  
      try {
        setIsLoading(true);
        const response = await fetch(`${BASE_URL}/api/update_font_size`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload_font),
        });
        const data = await response.json();
        if (data.status === 'Success.') {
          sessionStorage.setItem('font_size', newFontSize);
          setFontSize(newFontSize);
        } else {
          alert('Failed to update font size.');
        }
      } catch (error) {
        console.error('Error updating font size:', error);
        alert('An error occurred while updating font size.');
      } finally {
        setIsLoading(false);
      }
    };



  return (
    <Box className="settings-page">
    <NavBar />
    {isLoading ? (
      <Box className="loading-container">
        <CircularProgress sx={{ color: 'black', marginBottom: '10px' }} />
        <Typography variant="h7" align="center" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
          Switching to {selectedLanguage} and loading chat data...
        </Typography>
        <Typography variant="body1" align="center">
          BAIA is getting everything ready for you. This will just take a moment.
        </Typography>
      </Box>
    ) : (
      <Box className="settings-main">
        <Box className="settings-sidebar">
          <Typography
            variant="h6"
            className={`settings-title ${selectedSection === 'language' ? 'active' : ''}`}
            onClick={() => handleSectionClick('language')}
          >
            <TranslateIcon sx={{mr:2,}}/> Language Settings
          </Typography>
          <Typography
            variant="h6"
            className={`settings-title ${selectedSection === 'voice' ? 'active' : ''}`}
            onClick={() => handleSectionClick('voice')}
          >
            <SettingsVoiceIcon sx={{mr:2,}}/>Voice Settings
          </Typography>
          <Typography
            variant="h6"
            className={`settings-title ${selectedSection === 'font' ? 'active' : ''}`}
            onClick={() => handleSectionClick('font')}
          >
            <FormatSizeIcon sx={{mr:2,}}/> Font Settings
          </Typography>
        </Box>
        <Box className="settings-content">
          {selectedSection === 'language' && (
            <Box className="settings-card">
              <Typography variant="h5" className="settings-header">Language Preferences</Typography>
              {!isEditMode ? (
                <Box className="language-container">
                  <Typography variant="subtitle1" className="current-language">
                    Current Language: <span>{selectedLanguage}</span>
                  </Typography>
                  <Button
                    variant="contained"
                    className="update-button"
                    onClick={handleEditClick}
                    sx={{
                      backgroundColor: "rgb(70, 3, 90)",
                      '&:hover': {
                        backgroundColor: 'rgb(70,3,90)',
                        borderColor: 'rgb(58, 6, 107)',
                      },
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              ) : (
                <>
                  <FormControl fullWidth className="settings-form-control">
                    <InputLabel className="settings-label">Language</InputLabel>
                    <Select
                      value={selectedLanguage}
                      onChange={handleLanguageChange}
                      className="settings-select"
                    >
                      {availableLanguages.map((language, index) => (
                        <MenuItem key={index} value={language}>{language}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    className="update-button"
                    onClick={handleUpdateLanguage}
                    sx={{
                      backgroundColor: "rgb(70, 3, 90)",
                      '&:hover': {
                        backgroundColor: 'rgb(70,3,90)',
                        borderColor: 'rgb(58, 6, 107)',
                      },
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    variant="outlined"
                    className="cancel-button"
                    onClick={handleCancelClick}
                    sx={{ border: '1px solid rgb(70,3,90)', color: 'rgb(70,3,90)' }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Box>
          )}
          {selectedSection === 'voice' && (
            <Box className="settings-card">
              {!hasVoiceConsent ? (
                <Box className="consent">
                  <Typography variant="h5" className="settings-header">Voice Consent</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={voiceConsentChecked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        I agree to give my voice consent with the understanding that it may involve the transfer of data to third parties. I have also read and understood the
                        {' '}
                        <a href="https://www.baia.ai/terms-and-condition/" target="_blank" rel="noopener noreferrer">Terms</a>,{' '}
                        <a href="https://www.baia.ai/disclaimer/" target="_blank" rel="noopener noreferrer">Disclaimer</a>, and{' '}
                        <a href="https://www.baia.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                      </Typography>
                    }
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleVoiceConsentSubmit}
                    sx={{
                      bgcolor: 'rgb(70, 3, 90)', marginTop: "10px",
                      '&:hover': {
                        backgroundColor: 'rgb(70,3,90)',
                        borderColor: 'rgb(58, 6, 107)',
                      },
                    }}
                  >
                    Submit Consent
                  </Button>
                </Box>
              ) : (
                <Box className="voice-character-section">
                  <Typography variant="h5" className="settings-header">Voice Character Settings</Typography>
                  <Box className="voice-settings-card">
                    <div className={`voice-settings-card-content ${!isProOrAdvancedUser ? 'blurred' : ''}`}>
                      <FormControl fullWidth className="voice-character-select">
                        <InputLabel className="settings-select-label">Select Gender</InputLabel>
                        <Select
                          value={gender}
                          onChange={handleGenderChange}
                          displayEmpty
                          className="settings-select"
                        >
                          <MenuItem value="" disabled hidden>Select Gender</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth className="voice-character-select">
                        <InputLabel className="settings-select-label">Select Voice Character</InputLabel>
                        <Select
                          value={voiceCharacter}
                          onChange={handleVoiceCharacterChange}
                          displayEmpty
                          className="settings-select"
                          disabled={!gender}
                        >
                          <MenuItem value="" disabled hidden>Select Voice Character</MenuItem>
                          {gender === 'Female' && characterCodes.Female.map((code) => (
                            <MenuItem key={code} value={code}>{code}</MenuItem>
                          ))}
                          {gender === 'Male' && characterCodes.Male.map((code) => (
                            <MenuItem key={code} value={code}>{code}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!gender || !voiceCharacter}
                        className="save-button"
                        sx={{ backgroundColor: "rgb(70, 3, 90)" }}
                      >
                        Save Changes
                      </Button>
                    </div>
                    {!isProOrAdvancedUser && (
                      <div className="settings-overlay">
                        <LockIcon color="error" sx={{ color: "black" }} />
                        <Typography variant="body1" className="overlay-text">
                          This feature is only available for Pro and Advanced users.
                        </Typography>
                      </div>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {selectedSection === 'font' && (
  <Box className="settings-card" sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
  <Typography variant="h5" className="settings-header">Font Size Settings</Typography>
  <Select
    value={newFontSize} // Set the default value to newFontSize
    onChange={handleFontSizeChange} // Pass the event directly
    sx={{ marginTop: 2, marginBottom: 6 }}
  >
    {[12, 14, 16, 18, 20].map(size => (
      <MenuItem key={size} value={size}>
        {size}px
      </MenuItem>
    ))}
  </Select>
  <Button
    variant="contained"
    color="primary"
    onClick={handleUpdateFontSize} // Handle font size update
    sx={{
      marginTop: 5,
      position: 'absolute',
      bottom: 10,
      right: 10,
      bgcolor: 'rgb(70, 3, 90)',
      '&:hover': {
        bgcolor: 'rgb(70, 3, 90)',
      }
    }}
    disabled={isLoading} // Disable button while loading
  >
    {isLoading ? 'Updating...' : 'Update'}
  </Button>
</Box>
)}
        </Box>
      </Box>
    )}
      <div className="home-footer">
  <p>
    BAIA: Your AI-Powered Emotional Support Assistant{' '}
    <a href="https://www.baia.ai/terms-and-condition/ " target="_blank">Terms</a>,{' '}
    <a href="https://www.baia.ai/disclaimer/" target="_blank">Disclaimer</a>{' '}
    and <a href="https://www.baia.ai/privacy-policy/" target="_blank">Privacy Policy</a> || Version: Beta-4.0
  </p>
</div>
  </Box>
  );
};

export default SettingsPage;
