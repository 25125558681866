// import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
// import { InputBase, IconButton, Popover, Box } from '@mui/material';
// import StopCircleIcon from '@mui/icons-material/StopCircle';
// import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon } from '@mui/icons-material';
// import Picker from '@emoji-mart/react';
// import { useTheme, useMediaQuery } from '@mui/material';
// import SubscriptionModal from '../utils/SubscriptionModal';
// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
// import AccessibilityIcon from '@mui/icons-material/Accessibility'; // Hands-free icon

// import nlp from 'compromise';

// const MessageInput = ({ onSend, isRenderingRef }) => {
//   const [message, setMessage] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [cursorPosition, setCursorPosition] = useState(0);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const abortControllerRef = useRef(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const recognitionRef = useRef(null);
//   const [isListening, setIsListening] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');
//   const [handfreeEnabled, setHandfreeEnabled] = useState(sessionStorage.getItem('handfreeenabled') === 'false');

//   const languagesPool = {
//     French: "fr-FR",
//     English: "en-US",
//     Spanish: "es-ES",
//   };
//   const languageCode = languagesPool[selectedLanguage];

//   const initSpeechRecognition = () => {
//     if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//       const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const recognition = new SpeechRecognition();
//       recognition.continuous = true;
//       recognition.interimResults = true;
//       recognition.lang = languageCode;
  
//       recognition.onstart = () => {
//         setIsListening(true);
//       };
  
//       recognition.onresult = (event) => {
//         let interimTranscript = '';
//         let finalTranscript = '';
  
//         for (let i = event.resultIndex; i < event.results.length; i++) {
//           const result = event.results[i];
//           if (result.isFinal) {
//             finalTranscript += result[0].transcript;
//           } else {
//             interimTranscript += result[0].transcript;
//           }
//         }
  
//         const correctedFinalTranscript = correctText(finalTranscript);
//         const correctedInterimTranscript = correctText(interimTranscript);
  
//         setMessage(correctedFinalTranscript + correctedInterimTranscript);
//       };
  
//       recognition.onend = () => {
//         setIsListening(false);
//         recognitionRef.current = null;
//       };
  
//       recognitionRef.current = recognition;
//     } else {
//       console.error('SpeechRecognition is not supported in this browser.');
//     }
//   };

//   const correctText = (text) => {
//     let doc = nlp(text);
//     let correctedText = doc.sentences().toLowerCase().out('text');
//     correctedText = doc.sentences().toTitleCase().out('text');
//     return correctedText.trim();
//   };

//   const handleMicClick = () => {
//     if (recognitionRef.current) {
//       recognitionRef.current.stop();
//     } else {
//       initSpeechRecognition();
//       recognitionRef.current.start();
//     }
//   };

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const checkSubscriptionStatus = () => {
//     const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
//     const intial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')).enable_casual_chat_session_status;

//     if (intial_sub) {
//       return { canProceed: true, subscriptionData };
//     }

//     if (subscriptionData.subscription_status) {
//       if (subscriptionData.daily_time_limit_quota) {
//         return { canProceed: false, text: "Your daily time limit quota is over. Please subscribe to the Pro version." };
//       }

//       return { canProceed: true, subscriptionData };
//     }

//     return { canProceed: false, text: "No central subscription." };
//   };

//   const handleInputChange = (event) => {
//     setMessage(event.target.value);
//     setCursorPosition(event.target.selectionStart);
//   };

//   const handleKeyPress = (event) => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (event.key === 'Enter') {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       if (isMobile || event.shiftKey) {
//         event.preventDefault();
//         const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
//         setMessage(newMessage);
//         setCursorPosition(cursorPosition + 1);
//       } else {
//         event.preventDefault();
//         handleSend();
//       }
//     }
//   };

//   const handleSend = () => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (message.trim()) {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       abortControllerRef.current = new AbortController();
//       onSend(message, abortControllerRef.current);
//       setMessage('');
//     }
//   };

//   const handleStopRendering = () => {
//     isRenderingRef.current = false;
//     sessionStorage.setItem('isRendering', 'false');
//     if (abortControllerRef.current) {
//       abortControllerRef.current.abort();
//     }
//   };

//   const handleOpenPopover = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClosePopover = () => {
//     setAnchorEl(null);
//   };

//   const handleEmojiSelect = (emoji) => {
//     const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
//     setMessage(newMessage);
//     setCursorPosition(cursorPosition + emoji.native.length);
//     handleClosePopover();
//   };

//   const toggleHandfree = () => {
//     const newHandfreeState = !handfreeEnabled;
//     setHandfreeEnabled(newHandfreeState);
//     sessionStorage.setItem('handfreeenabled', newHandfreeState.toString());
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'emoji-popover' : undefined;

//   return (
//     <div className="text-field">
//       <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClosePopover}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//         <Box p={2}>
//           <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
//         </Box>
//       </Popover>
//       <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             fontFamily: 'Roboto',
//             left: '0px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//           // style={{
//           //   position: 'absolute',
//           //   left: '0px',
//           //   bottom: '5px',
//           //   display: 'flex',
//           //   alignItems: 'center',
//           //   zIndex: 1,
//           //   paddingRight: '10px' // Add padding to prevent overlap
//           // }}
//         >
//           <IconButton onClick={toggleHandfree} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <AccessibilityIcon sx={{ color: handfreeEnabled ? 'rgb(65, 6, 83)' : 'grey' }} />
//           </IconButton>
//           <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>
//         </div>
//         <InputBase
//           placeholder="Type your message..."
//           value={message}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           multiline
//           sx={{
//             zIndex: 9999,
//             flex: 1,
//             width: 'calc(100% - 130px)',
//             paddingLeft: 3,
//             minHeight: '51px',
//             maxHeight: '150px',
//             borderRadius: '20px',
//             backgroundColor: 'var(--color-white)',
//             fontSize: '16px',
//             padding: '5px 10px', // Adjust padding to reduce space
//             marginLeft: '100px',
//             marginRight: '100px',
//             overflowY: 'auto',
//             wordBreak: 'break-word',
//             fontFamily: 'Roboto',
//             lineHeight: 1.5, // Adjust line-height to reduce space between lines
//           }}
//           // sx={{
//           //   flex: 1,
//           //   paddingLeft: '50px', // Ensure there is space for icons
//           //   minHeight: '51px',
//           //   maxHeight: '150px',
//           //   borderRadius: '20px',
//           //   backgroundColor: 'var(--color-white)',
//           //   fontSize: '16px',
//           //   padding: '5px 10px',
//           //   marginLeft: '100px', // Adjust margin for better spacing
//           //   marginRight: '100px',
//           //   overflowY: 'auto',
//           //   wordBreak: 'break-word',
//           //   fontFamily: 'Roboto',
//           //   lineHeight: 1.5,
//           // }}
//         />
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             right: '5px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           <IconButton
//             onClick={handleMicClick}
//             sx={{
//               fontSize: isMobile ? 'medium' : 'large',
//               borderRadius: '50%',
//               padding: isListening ? '8px' : '0',
//               backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//               border: isListening ? '2px solid rgb(65, 6, 83)' : 'none',
//               '&:hover': {
//                 backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//               },
//             }}
//           >
//             {isListening ? (
//               <MicIcon sx={{ color: 'white' }} />
//             ) : (
//               <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             )}
//           </IconButton>
//           <IconButton
//             onClick={isRenderingRef.current ? handleStopRendering : handleSend}
//             sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//           >
//             {isRenderingRef.current ? (
//               <StopCircleIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             ) : (
//               <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             )}
//           </IconButton>
//         </div>
//       </div>
//       <SubscriptionModal open={isModalOpen} onClose={handleCloseModal} />
//     </div>
//   );
// };

// MessageInput.propTypes = {
//   onSend: PropTypes.func.isRequired,
//   isRenderingRef: PropTypes.object.isRequired,
// };

// export default MessageInput;


// import React, { useState, useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { InputBase, IconButton, Popover, Box } from '@mui/material';
// import StopCircleIcon from '@mui/icons-material/StopCircle';
// import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon } from '@mui/icons-material';
// import Picker from '@emoji-mart/react';
// import { useTheme, useMediaQuery } from '@mui/material';
// import SubscriptionModal from '../utils/SubscriptionModal';
// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
// import AccessibilityIcon from '@mui/icons-material/Accessibility'; // Hands-free icon
// import nlp from 'compromise';

// const MessageInput = ({ onSend, isRenderingRef }) => {
//   const [message, setMessage] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [cursorPosition, setCursorPosition] = useState(0);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const abortControllerRef = useRef(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const recognitionRef = useRef(null);
//   const [isListening, setIsListening] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');
//   const [handfreeEnabled, setHandfreeEnabled] = useState(sessionStorage.getItem('handfreeenabled')  || 'false');
//   const [audioEnded, setAudioEnded] = useState(sessionStorage.getItem('audioEnded') || 'false');

//   const languagesPool = {
//     French: "fr-FR",
//     English: "en-US",
//     Spanish: "es-ES",
//   };
//   const languageCode = languagesPool[selectedLanguage];

//   useEffect(() => {
//     if (handfreeEnabled && audioEnded) {
//       console.log("audioEnded   ---> ", audioEnded)
//       console.log("handfreeEnabled   ---> ", handfreeEnabled)
//       initSpeechRecognition();
//       recognitionRef.current?.start();
//     } else {
//       recognitionRef.current?.stop();
//     }
//   }, [handfreeEnabled, audioEnded]);
  
//   // }, [handfreeEnabled, audioEnded]);

//   const initSpeechRecognition = () => {
//     if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//       const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const recognition = new SpeechRecognition();
//       recognition.continuous = true;
//       recognition.interimResults = true;
//       recognition.lang = languageCode;

//       recognition.onstart = () => {
//         setIsListening(true);
//       };

//       recognition.onresult = (event) => {
//         let interimTranscript = '';
//         let finalTranscript = '';

//         for (let i = event.resultIndex; i < event.results.length; i++) {
//           const result = event.results[i];
//           if (result.isFinal) {
//             finalTranscript += result[0].transcript;
//           } else {
//             interimTranscript += result[0].transcript;
//           }
//         }

//         const correctedFinalTranscript = correctText(finalTranscript);
//         const correctedInterimTranscript = correctText(interimTranscript);

//         setMessage(correctedFinalTranscript + correctedInterimTranscript);
//       };

//       recognition.onend = () => {
//         setIsListening(false);
//         recognitionRef.current = null;
//         setAudioEnded(true); // Set audioEnded to true when recognition ends
//       };

//       recognitionRef.current = recognition;
//     } else {
//       console.error('SpeechRecognition is not supported in this browser.');
//     }
//   };

//   const correctText = (text) => {
//     let doc = nlp(text);
//     let correctedText = doc.sentences().toLowerCase().out('text');
//     correctedText = doc.sentences().toTitleCase().out('text');
//     return correctedText.trim();
//   };

//   const handleMicClick = () => {
//     if (recognitionRef.current) {
//       recognitionRef.current.stop();
//     } else {
//       initSpeechRecognition();
//       recognitionRef.current.start();
//     }
//   };

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const checkSubscriptionStatus = () => {
//     const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
//     const intial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')).enable_casual_chat_session_status;

//     if (intial_sub) {
//       return { canProceed: true, subscriptionData };
//     }

//     if (subscriptionData.subscription_status) {
//       if (subscriptionData.daily_time_limit_quota) {
//         return { canProceed: false, text: "Your daily time limit quota is over. Please subscribe to the Pro version." };
//       }

//       return { canProceed: true, subscriptionData };
//     }

//     return { canProceed: false, text: "No central subscription." };
//   };

//   const handleInputChange = (event) => {
//     setMessage(event.target.value);
//     setCursorPosition(event.target.selectionStart);
//   };

//   const handleKeyPress = (event) => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (event.key === 'Enter') {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       if (isMobile || event.shiftKey) {
//         event.preventDefault();
//         const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
//         setMessage(newMessage);
//         setCursorPosition(cursorPosition + 1);
//       } else {
//         event.preventDefault();
//         handleSend();
//       }
//     }
//   };

//   const handleSend = () => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (message.trim()) {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       abortControllerRef.current = new AbortController();
//       onSend(message, abortControllerRef.current);
//       setMessage('');
//       // recognitionRef.current.stop()
//     }
//   };

//   const handleStopRendering = () => {
//     isRenderingRef.current = false;
//     sessionStorage.setItem('isRendering', 'false');
//     if (abortControllerRef.current) {
//       abortControllerRef.current.abort();
//     }
//   };

//   const handleOpenPopover = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClosePopover = () => {
//     setAnchorEl(null);
//   };

//   const handleEmojiSelect = (emoji) => {
//     const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
//     setMessage(newMessage);
//     setCursorPosition(cursorPosition + emoji.native.length);
//     handleClosePopover();
//   };

//   const toggleHandfree = () => {
//     const newHandfreeState = !handfreeEnabled;
//     setHandfreeEnabled(newHandfreeState);
//     sessionStorage.setItem('handfreeenabled', newHandfreeState.toString());
    
//   };

  

//   const open = Boolean(anchorEl);
//   const id = open ? 'emoji-popover' : undefined;
//   const details_product_name=JSON.parse(sessionStorage.getItem('central_subscription')).product_name
//   const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
//   const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;


//   const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
//                               sessionStorage.getItem('consent') === 'true';

//   return (
//     <div className="text-field">
//       <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClosePopover}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//         <Box p={2}>
//           <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
//         </Box>
//       </Popover>
//       <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             left: '0px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//             paddingRight: '10px' // Add padding to prevent overlap
//           }}
//         >
//           <IconButton onClick={toggleHandfree} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <AccessibilityIcon sx={{ color: handfreeEnabled ? 'rgb(65, 6, 83)' : 'grey' }} />
//           </IconButton>
//           <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>
//         </div>
//         <InputBase
//           placeholder="Type your message..."
//           value={message}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           multiline
//           sx={{
//             flex: 1,
//             paddingLeft: '50px', // Ensure there is space for icons
//             minHeight: '51px',
//             maxHeight: '150px',
//             borderRadius: '20px',
//             backgroundColor: 'var(--color-white)',
//             fontSize: '16px',
//             padding: '5px 10px',
//             marginLeft: '100px', // Adjust margin for better spacing
//             marginRight: '100px',
//             overflowY: 'auto',
//             wordBreak: 'break-word',
//             fontFamily: 'Roboto',
//             lineHeight: 1.5,
//           }}
//         />
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             right: '5px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           {shouldShowMicIcon && (
//             <IconButton
//             onClick={handleMicClick}
//             sx={{
//               fontSize: isMobile ? 'medium' : 'large',
//               borderRadius: '50%',
//               padding: isListening ? '8px' : '0',
//               backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//               border: isListening ? '2px solid rgb(65, 6, 83)' : 'none',
//               '&:hover': {
//                 backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//               },
//             }}
//           >
//             {isListening ? (
//               <MicIcon sx={{ color: 'white' }} />
//             ) : (
//               <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             )}
//           </IconButton>
//         )}

//           <IconButton
//             onClick={isRenderingRef.current ? handleStopRendering : handleSend}
//             sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//           >
//             {isRenderingRef.current ? (
//               <StopCircleIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             ) : (
//               <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             )}
//           </IconButton>
//         </div>
//       </div>
//       <SubscriptionModal open={isModalOpen} onClose={handleCloseModal} />
//     </div>
//   );
// };

// MessageInput.propTypes = {
//   onSend: PropTypes.func.isRequired,
//   isRenderingRef: PropTypes.object.isRequired,
// };

// export default MessageInput;


// import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
// import { InputBase, IconButton, Popover, Box } from '@mui/material';
// import StopCircleIcon from '@mui/icons-material/StopCircle';
// import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon, Accessibility as AccessibilityIcon } from '@mui/icons-material';
// import Picker from '@emoji-mart/react';
// import { useTheme, useMediaQuery } from '@mui/material';
// import SubscriptionModal from '../utils/SubscriptionModal';
// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';

// import nlp from 'compromise';

// const MessageInput = ({ onSend, isRenderingRef }) => {
//   const [message, setMessage] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [cursorPosition, setCursorPosition] = useState(0);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const abortControllerRef = useRef(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const recognitionRef = useRef(null);
//   const [isListening, setIsListening] = useState(false);
//   const [lastTranscript, setLastTranscript] = useState('');
//   const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');
//   const [handfreeEnabled, setHandfreeEnabled] = useState(false); // New state for hands-free mode
//   const [audioEnded, setAudioEnded] = useState(false); // New state for audio ended

//   const languagesPool = {
//     French: "fr-FR",
//     English: "en-US",
//     Spanish: "es-ES",
//   };
//   const languageCode = languagesPool[selectedLanguage];

//   const initSpeechRecognition = () => {
//     if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//       const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const recognition = new SpeechRecognition();
//       recognition.continuous = true; // Allow continuous speech input
//       recognition.interimResults = true; // Capture intermediate results
//       recognition.lang = languageCode;
  
//       recognition.onstart = () => {
//         setIsListening(true);
//       };
  
//       recognition.onresult = (event) => {
//         let interimTranscript = '';
//         let finalTranscript = '';
  
//         for (let i = event.resultIndex; i < event.results.length; i++) {
//           const result = event.results[i];
//           if (result.isFinal) {
//             finalTranscript += result[0].transcript;
//           } else {
//             interimTranscript += result[0].transcript;
//           }
//         }
  
//         // Apply basic grammar and punctuation correction
//         const correctedFinalTranscript = correctText(finalTranscript);
//         const correctedInterimTranscript = correctText(interimTranscript);
  
//         // Update the message with final and interim transcripts
//         setMessage(correctedFinalTranscript + correctedInterimTranscript);
//       };
  
//       recognition.onend = () => {
//         setIsListening(false);
//         setAudioEnded(true); // Set audioEnded to true when recognition ends
//         recognitionRef.current = null;
//       };
  
//       recognitionRef.current = recognition;
//     } else {
//       console.error('SpeechRecognition is not supported in this browser.');
//     }
//   };

//   const correctText = (text) => {
//     let doc = nlp(text);
//     let correctedText = doc.sentences().toLowerCase().out('text');
//     correctedText = doc.sentences().toTitleCase().out('text');
//     return correctedText.trim();
//   };

//   const handleMicClick = () => {
//     if (recognitionRef.current) {
//       recognitionRef.current.stop();
//     } else {
//       initSpeechRecognition();
//       recognitionRef.current.start();
//     }
//   };

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const checkSubscriptionStatus = () => {
//     const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
//     const intial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')).enable_casual_chat_session_status;

//     if (intial_sub) {
//       return { canProceed: true, subscriptionData };
//     }

//     if (subscriptionData.subscription_status) {
//       if (subscriptionData.daily_time_limit_quota) {
//         return { canProceed: false, text: "Your daily time limit quota is over. Please subscribe to the Pro version." };
//       }

//       return { canProceed: true, subscriptionData };
//     }

//     return { canProceed: false, text: "No central subscription." };
//   };

//   const handleInputChange = (event) => {
//     setMessage(event.target.value);
//     setCursorPosition(event.target.selectionStart);
//   };

//   const handleKeyPress = (event) => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (event.key === 'Enter') {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       if (isMobile || event.shiftKey) {
//         event.preventDefault();
//         const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
//         setMessage(newMessage);
//         setCursorPosition(cursorPosition + 1);
//       } else {
//         event.preventDefault();
//         handleSend();
//       }
//     }
//   };

//   const handleSend = () => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (message.trim()) {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       abortControllerRef.current = new AbortController();
//       onSend(message, abortControllerRef.current);
//       setMessage('');
//     }
//   };

//   const handleStopRendering = () => {
//     isRenderingRef.current = false;
//     sessionStorage.setItem('isRendering', 'false');
//     if (abortControllerRef.current) {
//       abortControllerRef.current.abort();
//     }
//   };

//   const handleOpenPopover = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClosePopover = () => {
//     setAnchorEl(null);
//   };

//   const handleEmojiSelect = (emoji) => {
//     const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
//     setMessage(newMessage);
//     setCursorPosition(cursorPosition + emoji.native.length);
//     handleClosePopover();
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'emoji-popover' : undefined;

//   // Determine whether to show MicIcon and AccessibilityIcon based on conditions

//   const details_product_name=JSON.parse(sessionStorage.getItem('central_subscription')).product_name
//   const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
//   const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;


//   const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
//                              sessionStorage.getItem('consent') === 'true';
//   const shouldShowAccessibilityIcon = handfreeEnabled || audioEnded; // Example condition for AccessibilityIcon

//   return (
//     <div className="text-field">
//       <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClosePopover}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//         <Box p={2}>
//           <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
//         </Box>
//       </Popover>
//       <div className="input-container" style={{ display: 'flex', alignItems: 'center' }}>
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             fontFamily: 'Roboto',
//             left: '0px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>
//         </div>
//         <InputBase
//           placeholder="Type your message..."
//           value={message}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           multiline
//           sx={{
//             zIndex: 9999,
//             flex: 1,
//             width: 'calc(100% - 130px)',
//             paddingLeft: 3,
//             minHeight: '51px',
//             maxHeight: '150px',
//             borderRadius: '20px',
//             backgroundColor: 'var(--color-white)',
//             fontSize: '16px',
//             padding: '5px 10px',
//             marginLeft: '20px',
//             marginRight: '100px',
//             overflowY: 'auto',
//             wordBreak: 'break-word',
//             fontFamily: 'Roboto',
//             lineHeight: 1.5,
//           }}
//         />
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             right: '5px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           {shouldShowMicIcon && (
//             <IconButton
//               onClick={handleMicClick}
//               sx={{
//                 fontSize: isMobile ? 'medium' : 'large',
//                 borderRadius: '50%',
//                 padding: isListening ? '8px' : '0',
//                 backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//                 border: isListening ? '2px solid rgb(65, 6, 83)' : 'none',
//                 '&:hover': {
//                   backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//                 },
//               }}
//             >
//               {isListening ? (
//                 <MicIcon sx={{ color: 'white' }} />
//               ) : (
//                 <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//               )}
//             </IconButton>
//           )}
//           {shouldShowAccessibilityIcon && (
//             <IconButton
//               sx={{
//                 fontSize: isMobile ? 'medium' : 'large',
//                 borderRadius: '50%',
//                 backgroundColor: 'transparent',
//                 '&:hover': {
//                   backgroundColor: 'rgba(65, 6, 83, 0.1)',
//                 },
//               }}
//             >
//               <AccessibilityIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             </IconButton>
//           )}
//           <IconButton
//             onClick={isRenderingRef.current ? handleStopRendering : handleSend}
//             sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//           >
//             {isRenderingRef.current ? (
//               <StopCircleIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             ) : (
//               <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             )}
//           </IconButton>
//         </div>
//       </div>
//       <SubscriptionModal open={isModalOpen} onClose={handleCloseModal} />
//     </div>
//   );
// };

// MessageInput.propTypes = {
//   onSend: PropTypes.func.isRequired,
//   isRenderingRef: PropTypes.object.isRequired,
// };

// export default MessageInput;



// import React, { useState, useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { InputBase, IconButton, Popover, Box } from '@mui/material';
// import StopCircleIcon from '@mui/icons-material/StopCircle';
// import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon } from '@mui/icons-material';
// import Picker from '@emoji-mart/react';
// import { useTheme, useMediaQuery } from '@mui/material';
// import SubscriptionModal from '../utils/SubscriptionModal';
// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
// import AccessibilityIcon from '@mui/icons-material/Accessibility';
// import nlp from 'compromise';

// const MessageInput = ({ onSend, isRenderingRef }) => {
//   const [message, setMessage] = useState('');
//   const [interimMessage, setInterimMessage] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [cursorPosition, setCursorPosition] = useState(0);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const abortControllerRef = useRef(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const recognitionRef = useRef(null);
//   const [isListening, setIsListening] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');
//   const [handfreeEnabled, setHandfreeEnabled] = useState(sessionStorage.getItem('handfreeenabled')  || 'false');
//   const [audioEnded, setAudioEnded] = useState(sessionStorage.getItem('audioEnded') || 'false');

//   const languagesPool = {
//     French: "fr-FR",
//     English: "en-US",
//     Spanish: "es-ES",
//   };
//   const languageCode = languagesPool[selectedLanguage];

//   useEffect(() => {
//     if (handfreeEnabled && audioEnded) {
//       initSpeechRecognition();
//       recognitionRef.current?.start();
//     } else {
//       recognitionRef.current?.stop();
//     }
//   }, [handfreeEnabled, audioEnded]);

//   const initSpeechRecognition = () => {
//     if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//       const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const recognition = new SpeechRecognition();
//       recognition.continuous = true;
//       recognition.interimResults = true;
//       recognition.lang = languageCode;

//       recognition.onstart = () => {
//         setIsListening(true);
//       };

//       recognition.onresult = (event) => {
//         let interimTranscript = '';
//         let finalTranscript = '';

//         for (let i = event.resultIndex; i < event.results.length; i++) {
//           const result = event.results[i];
//           if (result.isFinal) {
//             finalTranscript += result[0].transcript;
//             setMessage(prevMessage => {
//               // Remove extra spaces when combining interim and final messages
//               let combinedMessage = prevMessage.trim() + ' ' + correctText(finalTranscript).trim();
//               return combinedMessage.replace(/\s{2,}/g, ' '); // Ensure no multiple spaces
//             });
//             setInterimMessage('');
//           } else {
//             interimTranscript += result[0].transcript;
//             setInterimMessage(interimTranscript);
//           }
//         }
        
//         // Only update the interim message if there is new content
//         if (interimMessage.trim()) {
//           setMessage(prevMessage => {
//             // Remove extra spaces between interim and final messages
//             let combinedMessage = prevMessage.trim() + ' ' + correctText(interimMessage).trim();
//             return combinedMessage.replace(/\s{2,}/g, ' '); // Ensure no multiple spaces
//           });
//         }
//       };

//       recognition.onend = () => {
//         setIsListening(false);
//         recognitionRef.current = null;
//         setAudioEnded(true); // Set audioEnded to true when recognition ends
//       };

//       recognitionRef.current = recognition;
//     } else {
//       console.error('SpeechRecognition is not supported in this browser.');
//     }
//   };

//   const correctText = (text) => {
//     let doc = nlp(text);
//     let correctedText = doc.sentences().toLowerCase().out('text');
//     correctedText = doc.sentences().toTitleCase().out('text');

//     // Remove extra spaces
//     correctedText = correctedText.replace(/\s{2,}/g, ' ').trim();

//     return correctedText;
//   };

//   const handleMicClick = () => {
//     if (recognitionRef.current) {
//       recognitionRef.current.stop();
//     } else {
//       initSpeechRecognition();
//       recognitionRef.current.start();
//     }
//   };

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const checkSubscriptionStatus = () => {
//     const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
//     const intial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')).enable_casual_chat_session_status;

//     if (intial_sub) {
//       return { canProceed: true, subscriptionData };
//     }

//     if (subscriptionData.subscription_status) {
//       if (subscriptionData.daily_time_limit_quota) {
//         return { canProceed: false, text: "Your daily time limit quota is over. Please subscribe to the Pro version." };
//       }

//       return { canProceed: true, subscriptionData };
//     }

//     return { canProceed: false, text: "No central subscription." };
//   };

//   const handleInputChange = (event) => {
//     setMessage(event.target.value);
//     setCursorPosition(event.target.selectionStart);
//   };

//   const handleKeyPress = (event) => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (event.key === 'Enter') {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       if (isMobile || event.shiftKey) {
//         event.preventDefault();
//         const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
//         setMessage(newMessage);
//         setCursorPosition(cursorPosition + 1);
//       } else {
//         event.preventDefault();
//         handleSend();
//       }
//     }
//   };

//   const handleSend = () => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (message.trim()) {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       abortControllerRef.current = new AbortController();
//       onSend(message, abortControllerRef.current);
//       setMessage('');
//       setInterimMessage('');
//     }
//   };

//   const handleStopRendering = () => {
//     isRenderingRef.current = false;
//     sessionStorage.setItem('isRendering', 'false');
//     if (abortControllerRef.current) {
//       abortControllerRef.current.abort();
//     }
//   };

//   const handleOpenPopover = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClosePopover = () => {
//     setAnchorEl(null);
//   };

//   const handleEmojiSelect = (emoji) => {
//     const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
//     setMessage(newMessage);
//     setCursorPosition(cursorPosition + emoji.native.length);
//     handleClosePopover();
//   };

//   const toggleHandfree = () => {
//     const newHandfreeState = !handfreeEnabled;
//     setHandfreeEnabled(newHandfreeState);
//     sessionStorage.setItem('handfreeenabled', newHandfreeState.toString());
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'emoji-popover' : undefined;
//   const details_product_name = JSON.parse(sessionStorage.getItem('central_subscription')).product_name;
//   const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
//   const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

//   const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
//                               sessionStorage.getItem('consent') === 'true';

//   return (
//     <div className="text-field">
//       <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClosePopover}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//         <Box p={2}>
//           <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
//         </Box>
//       </Popover>
//       <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             left: '0px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//             paddingRight: '10px'
//           }}
//         >
//           <IconButton onClick={toggleHandfree} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <AccessibilityIcon sx={{ color: handfreeEnabled ? 'rgb(65, 6, 83)' : 'grey' }} />
//           </IconButton>
//           <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>
//         </div>
//         <InputBase
//           placeholder="Type your message..."
//           value={message}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           multiline
//           sx={{
//             flex: 1,
//             paddingLeft: '50px',
//             minHeight: '51px',
//             maxHeight: '150px',
//             borderRadius: '20px',
//             backgroundColor: 'var(--color-white)',
//             fontSize: '16px',
//             padding: '5px 10px',
//             marginLeft: '100px',
//             marginRight: '100px',
//             overflowY: 'auto',
//             wordBreak: 'break-word',
//             fontFamily: 'Roboto',
//             lineHeight: 1.5,
//           }}
//         />
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             right: '5px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           {shouldShowMicIcon && (
//             <IconButton
//               onClick={handleMicClick}
//               sx={{
//                 fontSize: isMobile ? 'medium' : 'large',
//                 borderRadius: '50%',
//                 padding: isListening ? '8px' : '0',
//                 backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//                 border: isListening ? '2px solid rgb(65, 6, 83)' : 'none',
//                 '&:hover': {
//                   backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//                 },
//               }}
//             >
//               {isListening ? (
//                 <MicIcon sx={{ color: 'white' }} />
//               ) : (
//                 <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//               )}
//             </IconButton>
//           )}
//           <IconButton
//             onClick={isRenderingRef.current ? handleStopRendering : handleSend}
//             sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//           >
//             {isRenderingRef.current ? (
//               <StopCircleIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             ) : (
//               <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             )}
//           </IconButton>
//         </div>
//       </div>
//       <SubscriptionModal open={isModalOpen} onClose={handleCloseModal} />
//     </div>
//   );
// };

// MessageInput.propTypes = {
//   onSend: PropTypes.func.isRequired,
//   isRenderingRef: PropTypes.object.isRequired,
// };

// export default MessageInput;



// import React, { useState, useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { InputBase, IconButton, Popover, Box } from '@mui/material';
// import StopCircleIcon from '@mui/icons-material/StopCircle';
// import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon } from '@mui/icons-material';
// import Picker from '@emoji-mart/react';
// import { useTheme, useMediaQuery } from '@mui/material';
// import SubscriptionModal from '../utils/SubscriptionModal';
// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
// import AccessibilityIcon from '@mui/icons-material/Accessibility';
// import nlp from 'compromise';

// const MessageInput = ({ onSend, isRenderingRef }) => {
//   const [message, setMessage] = useState('');
//   const [interimMessage, setInterimMessage] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [cursorPosition, setCursorPosition] = useState(0);
//   const [lastVoiceInputTime, setLastVoiceInputTime] = useState(null);
//   const [voiceTimeoutId, setVoiceTimeoutId] = useState(null);

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const abortControllerRef = useRef(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const recognitionRef = useRef(null);
//   const [isListening, setIsListening] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');
//   const [handfreeEnabled, setHandfreeEnabled] = useState(sessionStorage.getItem('handfreeenabled') || 'false');
//   const [audioEnded, setAudioEnded] = useState(sessionStorage.getItem('audioEnded') || 'false');

//   const languagesPool = {
//     French: "fr-FR",
//     English: "en-US",
//     Spanish: "es-ES",
//   };
//   const languageCode = languagesPool[selectedLanguage];

//   useEffect(() => {
//     if (handfreeEnabled && audioEnded) {
//       initSpeechRecognition();
//       recognitionRef.current?.start();
//     } else {
//       recognitionRef.current?.stop();
//     }
//   }, [handfreeEnabled, audioEnded]);

//   const initSpeechRecognition = () => {
//     if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//       const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const recognition = new SpeechRecognition();
//       recognition.continuous = true;
//       recognition.interimResults = true;
//       recognition.lang = languageCode;

//       recognition.onstart = () => {
//         setIsListening(true);
//       };

//       recognition.onresult = (event) => {
//         let interimTranscript = '';
//         let finalTranscript = '';

//         for (let i = event.resultIndex; i < event.results.length; i++) {
//           const result = event.results[i];
//           if (result.isFinal) {
//             finalTranscript += result[0].transcript;
//             setMessage(prevMessage => {
//               let combinedMessage = prevMessage.trim() + ' ' + correctText(finalTranscript).trim();
//               return combinedMessage.replace(/\s{2,}/g, ' ');
//             });
//             setInterimMessage('');
//             setLastVoiceInputTime(Date.now()); // Update last voice input time
//             resetVoiceTimeout(); // Reset timeout on new input
//           } else {
//             interimTranscript += result[0].transcript;
//             setInterimMessage(interimTranscript);
//           }
//         }

//         if (interimMessage.trim()) {
//           setMessage(prevMessage => {
//             let combinedMessage = prevMessage.trim() + ' ' + correctText(interimMessage).trim();
//             return combinedMessage.replace(/\s{2,}/g, ' ');
//           });
//           setLastVoiceInputTime(Date.now()); // Update last voice input time
//           resetVoiceTimeout(); // Reset timeout on interim input
//         }
//       };

//       recognition.onend = () => {
//         setIsListening(false);
//         recognitionRef.current = null;
//         setAudioEnded(true); // Mark recognition as ended
//       };

//       recognitionRef.current = recognition;
//     } else {
//       console.error('SpeechRecognition is not supported in this browser.');
//     }
//   };

//   const correctText = (text) => {
//     let doc = nlp(text);
//     let correctedText = doc.sentences().toLowerCase().out('text');
//     correctedText = doc.sentences().toTitleCase().out('text');
//     correctedText = correctedText.replace(/\s{2,}/g, ' ').trim();
//     return correctedText;
//   };

//   const resetVoiceTimeout = () => {
//     if (voiceTimeoutId) {
//       clearTimeout(voiceTimeoutId);
//     }

//     const timeoutId = setTimeout(() => {
//       const timeSinceLastInput = Date.now() - lastVoiceInputTime;
//       if (timeSinceLastInput >= 5000) {
//         handleSend(); // Auto-send the message if inactive for 5 seconds
//       }
//     }, 5000);

//     setVoiceTimeoutId(timeoutId);
//   };

//   const handleMicClick = () => {
//     if (recognitionRef.current) {
//       recognitionRef.current.stop();
//     } else {
//       initSpeechRecognition();
//       recognitionRef.current.start();
//     }
//   };

//   const handleInputChange = (event) => {
//     setMessage(event.target.value);
//     setCursorPosition(event.target.selectionStart);
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       event.preventDefault();
//       handleSend();
//     }
//   };

//   const handleSend = () => {
//     if (message.trim()) {
//       onSend(message, abortControllerRef.current);
//       setMessage('');
//       setInterimMessage('');
//       clearTimeout(voiceTimeoutId); // Clear timeout when message is sent
//     }
//   };

//   const handleEmojiSelect = (emoji) => {
//     const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
//     setMessage(newMessage);
//     setCursorPosition(cursorPosition + emoji.native.length);
//     handleClosePopover();
//   };

//   const handleOpenPopover = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClosePopover = () => {
//     setAnchorEl(null);
//   };

//   const toggleHandfree = () => {
//     const newHandfreeState = !handfreeEnabled;
//     setHandfreeEnabled(newHandfreeState);
//     sessionStorage.setItem('handfreeenabled', newHandfreeState.toString());
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'emoji-popover' : undefined;
//   const details_product_name = JSON.parse(sessionStorage.getItem('central_subscription')).product_name;
//   const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
//   const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

//   const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
//                               sessionStorage.getItem('consent') === 'true';


//   return (
//     <div className="text-field">
//       <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClosePopover}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//         <Box p={2}>
//           <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
//         </Box>
//       </Popover>
//       <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             left: '0px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//             paddingRight: '10px'
//           }}
//         >
//           <IconButton onClick={toggleHandfree} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <AccessibilityIcon sx={{ color: handfreeEnabled ? 'rgb(65, 6, 83)' : 'grey' }} />
//           </IconButton>
//           <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>
//         </div>
//         <InputBase
//           placeholder="Type your message..."
//           value={message}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           multiline
//           sx={{
//             flex: 1,
//             paddingLeft: '50px',
//             minHeight: '51px',
//             maxHeight: '150px',
//             borderRadius: '20px',
//             backgroundColor: 'var(--color-white)',
//             fontSize: '16px',
//             padding: '5px 10px',
//             marginLeft: '100px',
//             marginRight: '100px',
//             overflowY: 'auto',
//             wordBreak: 'break-word',
//             fontFamily: 'Roboto',
//             lineHeight: 1.5,
//           }}
//         />
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             right: '5px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           {shouldShowMicIcon && (
//             <IconButton
//               onClick={handleMicClick}
//               sx={{
//                 fontSize: isMobile ? 'medium' : 'large',
//                 borderRadius: '50%',
//                 padding: isListening ? '8px' : '0',
//                 backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//                 border: isListening ? '2px solid rgb(65, 6, 83)' : 'none',
//                 '&:hover': {
//                   backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//                 },
//               }}
//             >
//               {isListening ? (
//                 <MicIcon sx={{ color: 'white' }} />
//               ) : (
//                 <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//               )}
//             </IconButton>
//           )}
//           <IconButton
//             onClick={handleSend}
//             sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//           >
//             <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>
//         </div>
//       </div>
//       <SubscriptionModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
//     </div>
//   );
// };

// MessageInput.propTypes = {
//   onSend: PropTypes.func.isRequired,
//   isRenderingRef: PropTypes.object.isRequired,
// };

// export default MessageInput;



// import React, { useState, useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { InputBase, IconButton, Popover, Box } from '@mui/material';
// import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon } from '@mui/icons-material';
// import Picker from '@emoji-mart/react';
// import { useTheme, useMediaQuery } from '@mui/material';
// import SubscriptionModal from '../utils/SubscriptionModal';
// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
// import AccessibilityIcon from '@mui/icons-material/Accessibility';
// import nlp from 'compromise';

// const MessageInput = ({ onSend, isRenderingRef }) => {
//   const [message, setMessage] = useState('');
//   const [interimMessage, setInterimMessage] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [cursorPosition, setCursorPosition] = useState(0);
//   const [voiceTimeoutId, setVoiceTimeoutId] = useState(null);
  
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const abortControllerRef = useRef(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const recognitionRef = useRef(null);
//   const [isListening, setIsListening] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');
//   const [handfreeEnabled, setHandfreeEnabled] = useState(sessionStorage.getItem('handfreeenabled') || 'false');
//   const [audioEnded, setAudioEnded] = useState(sessionStorage.getItem('audioEnded') || 'false');
  
//   const lastVoiceInputTimeRef = useRef(Date.now());

//   const languagesPool = {
//     French: "fr-FR",
//     English: "en-US",
//     Spanish: "es-ES",
//   };
//   const languageCode = languagesPool[selectedLanguage];

//   useEffect(() => {
//     if (handfreeEnabled && audioEnded) {
//       initSpeechRecognition();
//       recognitionRef.current?.start();
//     } else {
//       recognitionRef.current?.stop();
//     }
//   }, [handfreeEnabled, audioEnded]);

//   useEffect(() => {
//     resetVoiceTimeout(); // Start the timeout when the component mounts
//     return () => {
//       clearTimeout(voiceTimeoutId); // Clean up on unmount
//     };
//   }, []);

//   const initSpeechRecognition = () => {
//     if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//       const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const recognition = new SpeechRecognition();
//       recognition.continuous = true;
//       recognition.interimResults = true;
//       recognition.lang = languageCode;

//       recognition.onstart = () => {
//         setIsListening(true);
//       };

//       recognition.onresult = (event) => {
//         let interimTranscript = '';
//         let finalTranscript = '';

//         for (let i = event.resultIndex; i < event.results.length; i++) {
//           const result = event.results[i];
//           if (result.isFinal) {
//             finalTranscript += result[0].transcript;
//             setMessage(prevMessage => {
//               let combinedMessage = prevMessage.trim() + ' ' + correctText(finalTranscript).trim();
//               return combinedMessage.replace(/\s{2,}/g, ' ');
//             });
//             setInterimMessage('');
//             lastVoiceInputTimeRef.current = Date.now();  // Update last voice input time
//             resetVoiceTimeout(); // Reset timeout on new input
//           } else {
//             interimTranscript += result[0].transcript;
//             setInterimMessage(interimTranscript);
//           }
//         }

//         if (interimMessage.trim()) {
//           setMessage(prevMessage => {
//             let combinedMessage = prevMessage.trim() + ' ' + correctText(interimMessage).trim();
//             return combinedMessage.replace(/\s{2,}/g, ' ');
//           });
//           lastVoiceInputTimeRef.current = Date.now(); // Update last voice input time
//           resetVoiceTimeout(); // Reset timeout on interim input
//         }
//       };

//       recognition.onend = () => {
//         setIsListening(false);
//         recognitionRef.current = null;
//         setAudioEnded(true); // Mark recognition as ended
//       };

//       recognitionRef.current = recognition;
//     } else {
//       console.error('SpeechRecognition is not supported in this browser.');
//     }
//   };

//   const correctText = (text) => {
//     let doc = nlp(text);
//     let correctedText = doc.sentences().toLowerCase().out('text');
//     correctedText = doc.sentences().toTitleCase().out('text');
//     correctedText = correctedText.replace(/\s{2,}/g, ' ').trim();
//     return correctedText;
//   };

//   const resetVoiceTimeout = () => {
//     if (voiceTimeoutId) {
//       clearTimeout(voiceTimeoutId);
//     }

//     const timeoutId = setTimeout(() => {
//       handleTimeoutSend(); // Call handleTimeoutSend after timeout
//     }, 5000);

//     setVoiceTimeoutId(timeoutId);
//   };

//   const handleTimeoutSend = () => {
//     if (message.trim()) {
//       handleSend();
//     }
//   };

//   const handleInputChange = (event) => {
//     setMessage(event.target.value);
//     setCursorPosition(event.target.selectionStart);
//     lastVoiceInputTimeRef.current = Date.now(); // Update last voice input time
//     resetVoiceTimeout(); // Reset timeout on user input
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       event.preventDefault();
//       handleSend();
//     }
//   };

//   const handleSend = () => {
//     if (message.trim()) {
//       onSend(message, abortControllerRef.current);
//       setMessage('');
//       setInterimMessage('');
//       clearTimeout(voiceTimeoutId); // Clear timeout when message is sent
//     }
//   };

//   const handleEmojiSelect = (emoji) => {
//     const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
//     setMessage(newMessage);
//     setCursorPosition(cursorPosition + emoji.native.length);
//     handleClosePopover();
//   };

//   const handleOpenPopover = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClosePopover = () => {
//     setAnchorEl(null);
//   };

//   const toggleHandfree = () => {
//     const newHandfreeState = !handfreeEnabled;
//     setHandfreeEnabled(newHandfreeState);
//     sessionStorage.setItem('handfreeenabled', newHandfreeState.toString());
//   };

//   const handleMicClick = () => {
//     if (isListening) {
//       // Stop the speech recognition
//       recognitionRef.current?.stop();
//       setIsListening(false);
//       setAudioEnded(true); // Mark recognition as ended
//     } else {
//       // Start speech recognition
//       if (handfreeEnabled && audioEnded) {
//         initSpeechRecognition();
//         recognitionRef.current?.start();
//       }
//       setIsListening(true);
//     }
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'emoji-popover' : undefined;
//   const details_product_name = JSON.parse(sessionStorage.getItem('central_subscription')).product_name;
//   const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
//   const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

//   const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
//                               sessionStorage.getItem('consent') === 'true';

//   return (
//     <div className="text-field">
//       <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClosePopover}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//         <Box p={2}>
//           <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
//         </Box>
//       </Popover>
//       <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             left: '0px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//             paddingRight: '10px'
//           }}
//         >
//           <IconButton onClick={toggleHandfree} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <AccessibilityIcon sx={{ color: handfreeEnabled ? 'rgb(65, 6, 83)' : 'grey' }} />
//           </IconButton>
//           <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>
//         </div>
//         <InputBase
//           placeholder="Type your message..."
//           value={message}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           multiline
//           sx={{
//             flex: 1,
//             paddingLeft: '50px',
//             minHeight: '51px',
//             maxHeight: '150px',
//             borderRadius: '20px',
//             backgroundColor: 'var(--color-white)',
//             fontSize: '16px',
//             padding: '5px 10px',
//             marginLeft: '100px',
//             marginRight: '100px',
//             overflowY: 'auto',
//             wordBreak: 'break-word',
//             fontFamily: 'Roboto',
//             lineHeight: 1.5,
//           }}
//         />
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             right: '5px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           {shouldShowMicIcon && (
//             <IconButton
//               onClick={handleMicClick}
//               sx={{
//                 fontSize: isMobile ? 'medium' : 'large',
//                 borderRadius: '50%',
//                 padding: isListening ? '8px' : '0',
//                 backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//                 border: isListening ? '2px solid rgb(65, 6, 83)' : 'none',
//                 '&:hover': {
//                   backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//                 },
//               }}
//             >
//               {isListening ? (
//                 <MicIcon sx={{ color: 'white' }} />
//               ) : (
//                 <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//               )}
//             </IconButton>
//           )}
//           <IconButton
//             onClick={handleSend}
//             sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//           >
//             <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>
//         </div>
//       </div>
//       <SubscriptionModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
//     </div>
//   );
// };

// MessageInput.propTypes = {
//   onSend: PropTypes.func.isRequired,
//   isRenderingRef: PropTypes.object.isRequired,
// };

// export default MessageInput;



//  BARD

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputBase, IconButton, Popover, Box } from '@mui/material';
import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon } from '@mui/icons-material';
import Picker from '@emoji-mart/react';
import { useTheme, useMediaQuery } from '@mui/material';
import SubscriptionModal from '../utils/SubscriptionModal';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import nlp from 'compromise';

const MessageInput = ({ onSend, isRenderingRef }) => {
  const [message, setMessage] = useState('');
  const [interimMessage, setInterimMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [voiceTimeoutId, setVoiceTimeoutId] = useState(null);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const abortControllerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const recognitionRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');
  const [handfreeEnabled, setHandfreeEnabled] = useState(sessionStorage.getItem('handfreeenabled') || 'false');
  const [audioEnded, setAudioEnded] = useState(sessionStorage.getItem('audioEnded') || 'false');
  
  const lastVoiceInputTimeRef = useRef(Date.now());

  const languagesPool = {
    French: "fr-FR",
    English: "en-US",
    Spanish: "es-ES",
  };
  const languageCode = languagesPool[selectedLanguage];

  useEffect(() => {
    if (handfreeEnabled) {
      initSpeechRecognition();
      recognitionRef.current?.start();
    } else {
      recognitionRef.current?.stop();
    }
  }, [handfreeEnabled]);

  useEffect(() => {
    resetVoiceTimeout(); // Start the timeout when the component mounts
    return () => {
      clearTimeout(voiceTimeoutId); // Clean up on unmount
    };
  }, []);

  const initSpeechRecognition = () => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = languageCode;

      recognition.onstart = () => {
        setIsListening(true);
      };

      recognition.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const result = event.results[i];
          if (result.isFinal) {
            finalTranscript += result[0].transcript;
            setMessage(prevMessage => {
              let combinedMessage = prevMessage.trim() + ' ' + correctText(finalTranscript).trim();
              return combinedMessage.replace(/\s{2,}/g, ' ');
            });
            setInterimMessage('');
            lastVoiceInputTimeRef.current = Date.now();  // Update last voice input time
            resetVoiceTimeout(); // Reset timeout on new input
          } else {
            interimTranscript += result[0].transcript;
            setInterimMessage(interimTranscript);
          }
        }

        if (interimMessage.trim()) {
          setMessage(prevMessage => {
            let combinedMessage = prevMessage.trim() + ' ' + correctText(interimMessage).trim();
            return combinedMessage.replace(/\s{2,}/g, ' ');
          });
          lastVoiceInputTimeRef.current = Date.now(); // Update last voice input time
          resetVoiceTimeout(); // Reset timeout on interim input
        }
      };

      recognition.onend = () => {
        setIsListening(false);
        recognitionRef.current = null;
        setAudioEnded(true); // Mark recognition as ended
      };

      recognitionRef.current = recognition;
    } else {
      console.error('SpeechRecognition is not supported in this browser.');
    }
  };

  const correctText = (text) => {
    let doc = nlp(text);
    let correctedText = doc.sentences().toLowerCase().out('text');
    correctedText = doc.sentences().toTitleCase().out('text');
    correctedText = correctedText.replace(/\s{2,}/g, ' ').trim();
    return correctedText;
  };

  const resetVoiceTimeout = () => {
    if (voiceTimeoutId) {
      clearTimeout(voiceTimeoutId);
    }

    const timeoutId = setTimeout(() => {
      handleTimeoutSend(); // Call handleTimeoutSend after timeout
    }, 5000);

    setVoiceTimeoutId(timeoutId);
  };

  const handleTimeoutSend = () => {
    const currentTime = Date.now();
    const timeSinceLastVoiceInput = currentTime - lastVoiceInputTimeRef.current;

    if (timeSinceLastVoiceInput >= 5000 && message.trim()) {
      handleSend();
    }
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    setCursorPosition(event.target.selectionStart);
    lastVoiceInputTimeRef.current = Date.now(); // Update last voice input time
    resetVoiceTimeout(); // Reset timeout on user input
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = () => {
    if (message.trim()) {
      onSend(message, abortControllerRef.current);
      setMessage('');
      setInterimMessage('');
      clearTimeout(voiceTimeoutId); // Clear timeout when message is sent
    }
  };

  const handleEmojiSelect = (emoji) => {
    const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
    setMessage(newMessage);
    setCursorPosition(cursorPosition + emoji.native.length);
    handleClosePopover();
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const toggleHandfree = () => {
    const newHandfreeState = !handfreeEnabled;
    setHandfreeEnabled(newHandfreeState);
    sessionStorage.setItem('handfreeenabled', newHandfreeState.toString());
  };

  const handleMicClick = () => {
    if (isListening) {
      // Stop the speech recognition
      recognitionRef.current?.stop();
      setIsListening(false);
      setAudioEnded(true); // Mark recognition as ended
    } else {
      // Start speech recognition
      if (handfreeEnabled && audioEnded) {
        initSpeechRecognition();
        recognitionRef.current?.start();
      }
      setIsListening(true);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'emoji-popover' : undefined;
  const details_product_name = JSON.parse(sessionStorage.getItem('central_subscription')).product_name;
  const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
  const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

  const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
                              sessionStorage.getItem('consent') === 'true';

  return (
    <div className="text-field">
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
        </Box>
      </Popover>
      <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <div
          className="icons-container"
          style={{
            position: 'absolute',
            left: '0px',
            bottom: '5px',
            display: 'flex',
            alignItems: 'center',
            zIndex: 1,
            paddingRight: '10px'
          }}
        >
          <IconButton onClick={toggleHandfree} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
            <AccessibilityIcon sx={{ color: handfreeEnabled ? 'rgb(65, 6, 83)' : 'grey' }} />
          </IconButton>
          <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
            <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
          </IconButton>
        </div>
        <InputBase
          placeholder="Type your message..."
          value={message}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          multiline
          sx={{
            flex: 1,
            paddingLeft: '50px',
            minHeight: '51px',
            maxHeight: '150px',
            borderRadius: '20px',
            backgroundColor: 'var(--color-white)',
            fontSize: '16px',
            padding: '5px 10px',
            marginLeft: '100px',
            marginRight: '100px',
            overflowY: 'auto',
            wordBreak: 'break-word',
            fontFamily: 'Roboto',
            lineHeight: 1.5,
          }}
        />
        <div
          className="icons-container"
          style={{
            position: 'absolute',
            right: '5px',
            bottom: '5px',
            display: 'flex',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          {shouldShowMicIcon && (
            <IconButton
              onClick={handleMicClick}
              sx={{
                fontSize: isMobile ? 'medium' : 'large',
                borderRadius: '50%',
                padding: isListening ? '8px' : '0',
                backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
                border: isListening ? '2px solid rgb(65, 6, 83)' : 'none',
                '&:hover': {
                  backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
                },
              }}
            >
              {isListening ? (
                <MicIcon sx={{ color: 'white' }} />
              ) : (
                <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
              )}
            </IconButton>
          )}
          <IconButton
            onClick={handleSend}
            sx={{ fontSize: isMobile ? 'medium' : 'large' }}
          >
            <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
          </IconButton>
        </div>
      </div>
      <SubscriptionModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

MessageInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  isRenderingRef: PropTypes.object.isRequired,
};

export default MessageInput;
