import { BASE_URL } from "../config";

// Function to fetch assessment data for a specific user
export const fetchAssessmentData = async (user) => {
    try {
        // Sending a POST request to the assessment analysis API
        const response = await fetch(`${BASE_URL}/api/combine_assessment_analysis_mb`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_name: user }), // Sending user data in the request body
        });

        // Parsing the response to JSON
        const data = await response.json();

        // Check if the response status is 'Success.'
        if (data.status === 'Success.') {
            // Creating an object to store filtered data
            const filteredData = {};

            // Iterating over the keys of the response data
            Object.keys(data.response).forEach(key => {
                if (data.response[key] != null) { // Check if the response data is not null
                    const filteredSubData = {}; // Object to store non-null sub-entries

                    // Iterating over sub-entries and filtering out null or empty values
                    Object.entries(data.response[key]).forEach(([subKey, value]) => {
                        if (value !== null && value !== "") {
                            filteredSubData[subKey] = value;
                        }
                    });

                    // Storing the filtered sub-data in the main filtered data object
                    filteredData[key] = filteredSubData;
                }
            });

            // Returning the filtered data on successful fetch
            return { success: true, data: filteredData };
        } else {
            // Returning the error status if fetch was not successful
            return { success: false, error: data.process_status };
        }
    } catch (error) {
        // Catching and returning any error that occurs during the fetch process
        return { success: false, error: error.message };
    }
};

// Function to fetch the combined report for a specific user and number of days
export const fetchCombinedReport = async (user, days) => {
    try {
        // Sending a POST request to the combined report API
        const response = await fetch(`${BASE_URL}/api/combined_report_extended`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_name: user, days, role: "user" }), // Sending user data, days, and role in the request body
        });

        // Parsing the response to JSON
        const data = await response.json();

        // Check if the response status is 'Success.'
        if (data.status === 'Success.') {
            // Returning the fetched data on successful fetch
            console.log(data)
            return { success: true, data };
        } else {
            // Returning the error status if fetch was not successful
            return { success: false, error: data.process_status };
        }
    } catch (error) {
        // Catching and returning any error that occurs during the fetch process
        return { success: false, error: error.message };
    }
};

// export const fetchCombinedReport = async (user, days) => {
//     try {
//         // Sending a POST request to the combined report API
//         const response = await fetch(`${BASE_URL}/api/combined_report`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ user_name: user, days, role: "user" }), // Sending user data, days, and role in the request body
//         });

//         // Parsing the response to JSON
//         const data = await response.json();

//         // Check if the response status is 'Success.'
//         if (data.status === 'Success.') {
//             // Returning the fetched data on successful fetch
//             return { success: true, data };
//         } else {
//             // Returning the error status if fetch was not successful
//             return { success: false, error: data.process_status };
//         }
//     } catch (error) {
//         // Catching and returning any error that occurs during the fetch process
//         return { success: false, error: error.message };
//     }
// };
