



import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Avatar,Grid } from '@mui/material';
import { BASE_URL } from '../config';
import { getSessionData } from '../utils/session';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const ProfilePic = ({ userName, profilePic, setProfilePic,editMode }) => {
  const inputFileRef = useRef(null);
  const toastRef = useRef(null);
  const sessionData =JSON.parse(sessionStorage.getItem('userInfo'))

  const user_name = sessionData.username; 
//   console.log(userName);
  useEffect(() => {
    fetchAndRenderImage(user_name);
  },);

  const fetchAndRenderImage = (user_name) => {
    fetch(`${BASE_URL}/fetch_image?user_name=${user_name}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // console.log(user_name)
          setProfilePic(data.response);
          // console.log(data.response)
          localStorage.setItem('image_data',profilePic)
        } else {
        //   setProfilePic(initialImage); // Assuming initialEncodedImage is defined
          console.error("No image found for this user");
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleFileUpload = () => {
    const file = inputFileRef.current.files[0];
    if (file.size > 10* 1024*1024) { 
      showToast("File size limit exceeded");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const base64data = e.target.result;
      console.log(base64data)
      const formData = {
        base64data: base64data,
        filename: file.name,
        user_name: user_name  // You can dynamically set this value as needed
      };

      fetch(`${BASE_URL}/upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            console.log(data.response);
            console.log("ImageUpdated");
            fetchAndRenderImage(user_name);
          } else {
            showToast(data.error || "Failed to upload image");
          }
        })
        .catch(error => {
          console.error('Error:', error);
          showToast('An error occurred while uploading the file.');
        });
    };
    reader.readAsDataURL(file);
  };

  const showToast = (message) => {
    const toast = toastRef.current;
    if (toast) {
      const desc = toast.querySelector("#desc");
      if (desc) {
        desc.textContent = message;
        toast.style.visibility = "visible";
        setTimeout(() => {
          toast.style.visibility = "hidden";
        }, 3000);
      }
    }
  };

  return (
  
    <Grid item xs={12} md={0} className="">
    <Box>
      {editMode && (
        <Box >
          <Button
            className="btn"
            variant="contained"
            color="primary"
            onClick={() => inputFileRef.current.click()}
            startIcon={<CloudUploadIcon />}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#1976d2',
              color: 'white',
              textTransform: 'none',
              padding: '5px 20px',
              fontSize: '10px',
              borderRadius: '8px',
              marginBottom:'30px'
            }}
          >
            Upload Image
          </Button>
          <input
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            id="input-file"
            className="hiddenFileInput"
            ref={inputFileRef}
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        </Box>
      )}
    </Box>
    <div ref={toastRef} className="toastMessage">
      <div id="desc">File size limit exceeded</div>
    </div>
  </Grid>
  );
};

export default ProfilePic;