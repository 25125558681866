

import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, IconButton, TextField, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import { useProfileImageContext } from '../context/ProfileImageContext'; // Adjust path as needed

const ChatMessage = ({ message, isUser, loading, onEdit }) => {
  // State variables
  const [profilePic, setProfilePic] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message);
  const [fontSize, setFontSize] = useState(sessionStorage.getItem('font_size') || '16px');

  // Hooks and media queries
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { profileImage } = useProfileImageContext();
  
  // Avatar logic
  const avatarAlt = isUser ? 'User' : 'Assistant';
  const avatarSrc = isUser ? profilePic : profileImage;

  // Effects
  useEffect(() => {
    const storedImageData = localStorage.getItem('image_data');
    if (storedImageData) {
      setProfilePic(storedImageData);
    }
    const storedFontSize = sessionStorage.getItem('font_size');
    if (storedFontSize) {
      setFontSize(storedFontSize);
    }
  }, []);

  // Handlers
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    if (onEdit) {
      onEdit(editedMessage); // Pass edited message to parent component
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedMessage(message); // Revert to original message if cancelled
  };

  // JSX for rendering the chat message
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: isUser ? 'row-reverse' : 'row',
        marginBottom: 0,
        position: 'relative',
        fontFamily: 'Roboto',
        '&:hover': {
          '& .edit-icon': {
            opacity: 1, // Show icon on hover
            color: 'white',
          },
        },
      }}
    >
      {/* Avatar */}
      <Avatar
        sx={{
          width: isMobile ? 35 : 45,
          height: isMobile ? 35 : 45,
          padding: isUser ? '0px' : '3px',
          marginRight: isUser ? 2 : 1,
          marginLeft: isUser ? 1 : 2,
          bgcolor: isUser ? 'primary.main' : 'background.default',
        }}
        alt={avatarAlt}
        src={avatarSrc}
      >
        {avatarSrc ? null : avatarAlt.charAt(0)}
      </Avatar>

      {/* Message Box */}
      <Box
        sx={{
          bgcolor: isEditing ? '#492E87' : isUser ? '#492E87' : 'white',
          color: isEditing ? 'white' : isUser ? 'white' : 'black',
          borderRadius: isEditing ? '10px' : isUser ? '12px 0px 12px 12px' : '0px 12px 12px 12px',
          padding: isEditing ? '0px' : isMobile ? '0px' : '0px',
          maxWidth: '90%',
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          boxShadow: isEditing ? 'none' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
          fontFamily: 'Roboto',
          fontSize: isMobile ? '14px' : 'inherit',
          position: 'relative',
        }}
      >
        {isEditing ? (
          <Box>
            {/* Edit Input */}
            <TextField
              fullWidth
              multiline
              rows={2}
              value={editedMessage}
              onChange={(e) => setEditedMessage(e.target.value)}
              variant="outlined"
              sx={{
                mb: 1,
                bgcolor: '#492E87',
                color: 'white',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#492E87',
                  },
                  '&:hover fieldset': {
                    borderColor: '#492E87',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#492E87',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
            />

            {/* Edit Actions */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={handleCancel} color="error">
                <CancelIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton onClick={handleSave} color="primary">
                <SendIcon sx={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Box>
        ) : loading ? (
          // Loading Dots Animation
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: 'gray',
                animation: 'loadingDots 1.4s infinite ease-in-out both',
                animationDelay: '0.3s',
              }}
            />
            <Box
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: 'gray',
                animation: 'loadingDots 1.4s infinite ease-in-out both',
                animationDelay: '0.4s',
                marginLeft: '4px',
              }}
            />
            <Box
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: 'gray',
                animation: 'loadingDots 1.4s infinite ease-in-out both',
                animationDelay: '0.5s',
                marginLeft: '4px',
              }}
            />
          </Box>
        ) : (
          // Message Text
          <Typography sx={{ fontFamily: 'Roboto', fontSize: `${fontSize}px` }}>
            {message}
          </Typography>
        )}

        {/* Edit Icon */}
        {isUser && !isEditing && (
          <IconButton
            className="edit-icon"
            sx={{
              position: 'absolute',
              top: '50%',
              left: isMobile ? -30 : -40,
              transform: 'translateY(-50%)',
              opacity: 0,
              transition: 'opacity 0.3s',
            }}
            onClick={handleEditClick}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ChatMessage;

